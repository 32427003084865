import { useMsal } from "@azure/msal-react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from "@mui/material";
import * as locales from "@mui/material/locale";
import moment from "moment";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import intl from "react-intl-universal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import avatarImg from "../../../assest/avatar.png";
import NotificationCircle from "../../../pages/notification/NotificationCircle";
import NotificationItemContainer from "../../../pages/notification/NotificationItemContainer";
import {
  getOrgSeetings,
} from "../../../redux/actions/FreeTrial/FreeTrial";
import { DoLogOutAction } from "../../../redux/actions/Login/Login";
import { theme } from "../../../redux/actions/Theme/Theme";
import { storeAllResponses } from "../../../redux/actions/TicketsManagement/TicketsManagement";
import { IState } from "../../../redux/reducers/rootReducers";
import { getNotifications } from "../../../services/timeline/timeline.service";
import ChatBot from "../../ChatBot/ChatBot";
import CommonPopover from "../../common/popover/CommonPopover";
import darkmodeImg from "./../../../assest/icons/menu/darkmode.svg";
import flagIcon from "./../../../assest/icons/menu/flag.svg";
import cog from "./../../../assest/icons/menu/settings.svg";
import CommonStyles from "./../../common/CommonStyles";

const getOrgSettingsFun = async (id: any) => {
  let res = await getOrgSeetings(id);
  return res;
};
export const getTimeLineById = async (pageCount: Number) => {
  let timelineLogs = await getNotifications(pageCount);
  return timelineLogs;
};

function parseName(name: string) {
  // Return the first letter of the string, in uppercase
  return name.charAt(0).toUpperCase();
}

export const FallbackAvatarForAgent = ({ src, alt, firstName, lastName, width=30, height=30, parentClassName="", borderRadius="50%" , fontSize="12px"}:any) => {
  const [imageSrc, setImageSrc] = useState(src);

  const handleError = () => {
    setImageSrc(firstName);
  };

  return (
    <Avatar
      alt={alt}
      src={imageSrc}
      onError={handleError}
      sx={{width:width,height:height, fontSize:fontSize, borderRadius:borderRadius}}
      className={`${parentClassName}`}
    >
      {
  !imageSrc ? 
    (firstName || lastName ? 
      `${firstName ? parseName(firstName) : ''}${lastName ? parseName(lastName) : ''}` :
      null) 
    : null
}
      </Avatar>
  );
};
// import  {ThemeStatus}  from "../../common/CustomTheme";
const TopNavbar: React.FC = () => {
  const currOrganisation = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getLoggedOrganisation
  );
  const currAgent = useSelector(
    (state: IState) => state?.agentReducer?.loggedInAgent
  );
  const navigate = useNavigate();
  const toggleTheme = useSelector((state: IState) => state.theme.theme);
  const useStyles = CommonStyles;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loc, setLoc] = useState([] as any);
  const profileImg = useSelector(
    (state: IState) => state?.agentReducer?.saveProfile
  );
  const open = Boolean(anchorEl);

  const [proOveranchorEl, setproOverAnchorEl] = useState(null);
  const popoverOpen = Boolean(proOveranchorEl);
  const [notifItem, setNotifItem] = useState<any>([]);
  const count = useSelector((state: IState) => state.NotifReducer.count);
  const allNotif = useSelector((state: IState) => state.NotifReducer.allNotif);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const editClick = (event: any) => {
    setproOverAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    const temp = Object.keys(locales);
    temp.unshift("enGb");
    setLoc(temp);
  }, [locales]);

  useEffect(() => {
    if(allNotif.length>0){
      setNotifItem(allNotif);
      setPage(1)
      setHasMore(true)
    }
  }, [allNotif]);

  const { instance, accounts } = useMsal();
  function LogOut() {
    console.log("accountsaccounts", accounts);
    instance.logout();
    dispatch(theme(false));
    localStorage.clear();
    dispatch(DoLogOutAction());
    dispatch(storeAllResponses({}));

    navigate("/");
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const handleClosePopover = () => {
    setproOverAnchorEl(null);
  };
  const toggleDarkTheme = () => {
    dispatch(theme(!toggleTheme));
  };
  useEffect(() => {
    getTimeLineById(page).then((res: any) => {
      if (res?.data?.length > 0) {
        console.log("all notif", res?.data);
        setNotifItem(res?.data);
        dispatch({
          type: "STORE_COUNT",
          payload: res?.unreadCount,
        });
      } else {
      }
    });
  }, []);
  useEffect(() => {
    console.log("currOrganisation topnavbar", currOrganisation);
    if (currOrganisation.id) {
      getOrgSettingsFun(currOrganisation.id).then((res: any) => {
        console.log("getOrgSettingsFun res", res);
        if (res?.data?.dateFormat == "en-Gb") {
          moment.locale("en-Gb");
        } else {
          moment.locale("en-US");
        }
      });
    }
  }, [currOrganisation]);

  const fetchNextWeek = () => {
    getTimeLineById(page + 1).then((res: any) => {
      if (res?.data?.length > 0) {
        setNotifItem((prevItems: any[]) => [...prevItems, ...res.data]);
      } else {
        setHasMore(false);
      }
    });
    setPage(page + 1);
  };

  return (
    <div className={`${classes.topBar} top-nav-bar`}>
      <Toolbar className="d-flx">
        <div>
          <ListItemText
            primary={
              <Typography variant="h6" className="pageTitle">
                {currOrganisation.name}
                {/* <div className="btnGrp">
                  <IconButton
                    aria-label="Notification"
                    aria-controls="notification"
                    aria-haspopup="true"
                    // onClick={handleMenu}
                    color="inherit"
                  >
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Notification"
                    aria-controls="notification"
                    aria-haspopup="true"
                    //onClick={handleMenu}
                    color="inherit"
                  >
                    <StarBorderIcon />
                  </IconButton>
                </div> */}
              </Typography>
            }
            secondary={intl.get("header.secondary.title")}
          />
        </div>
        <div className="sm-hide topbar-controls">
          <Link to={`/MyProfile`}>
            <IconButton color="inherit">
           
              {!profileImg || profileImg == "" ? (
                 <FallbackAvatarForAgent
                 src={``}
                 firstName={currAgent.firstName}
                 lastName={currAgent.lastName}
                 width={30}
                 height={30}
                 borderRadius="50%"
                 fontSize="12px"
             />
              ) : (
                <img
                  src={`${profileImg}`}
                  alt=""
                  className="proImg"
                  height={80}
                />
              )}
            </IconButton>
          </Link>
          <ChatBot /> 
          
          <IconButton onClick={toggleDarkTheme} color="inherit">
            <img src={darkmodeImg} alt="" />
          </IconButton>
          {/* <IconButton color="inherit">
            <img src={printer} alt="" />
          </IconButton> */}
          <Link to={`/Preferences`}>
            <IconButton color="inherit">
              {/* <img src={preferencesIcon} height={20} alt="" />*/}
              <span className="simple-icon icon-equalizer"></span> 
            </IconButton>
          </Link>
          <Link to={`/organisationSettings`}>
            <IconButton color="inherit">
              <img src={cog} alt="" />
            </IconButton>
          </Link>
          {/* <IconButton color="inherit">
            <img src={helpIcon} alt="" />
          </IconButton> */}
          <IconButton color="inherit" style={{ position: "relative" }}>
            <NotificationsNoneIcon
              style={{ color: "inherit" }}
              // className={classes.notifIcon}
              onClick={editClick}
            />
            <NotificationCircle count={count} />
          </IconButton>

          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(event: any) => {
              setAnchorEl(event.currentTarget);
            }}
            color="inherit"
          >
            <img className="flagImg" src={flagIcon} alt="" />
          </IconButton>
          <Menu
            id="basic-menu"
            sx={{ height: 300 }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {loc?.map((item: any) => {
              return (
                <MenuItem
                  onClick={(ele: any) => {
                    let temp = `${item.substring(0, 2)}-${item.substring(
                      2,
                      4
                    )}`;
                    console.log("clicked lang", temp);
                    localStorage.setItem("lang", temp);
                    moment.locale(temp);
                    handleClose();
                  }}
                >
                  {item.substring(0, 2)}-{item.substring(2, 4)}
                </MenuItem>
              );
            })}
          </Menu>

          {/*   <IconButton color="inherit" className="notificationIcon">
            <img src={notificationIcon} alt="" />
            <span className="count">3</span>
          </IconButton> */}
          <IconButton className="logoutBtn" color="inherit" onClick={LogOut}>
            <LogoutOutlinedIcon />
          </IconButton>
        </div>
      </Toolbar>
      <CommonPopover
        id={`viewContrsolss`}
        open={popoverOpen}
        anchorEl={proOveranchorEl}
        handleClose={handleClosePopover}
        bodyContent={
          <>
            <div
              style={{
                // backgroundColor: "#EAEDFF",
                padding: "8px 10px 5px 10px",
                display: "flex",
                alignItems: "center",
                gap: 5,
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: 600,
                  marginBottom: "5px",
                }}
              >
                {" "}
                Notifications
              </div>
              {/* <div style={{fontSize:"12px"}}>Mark as Read All</div> */}
            </div>
            <Divider />

            <div style={{ overflowY: "auto", height: "400px", width: "420px" }}>
              <InfiniteScroll
                dataLength={notifItem.length ? notifItem.length : 0}
                next={fetchNextWeek}
                height={400}
                hasMore={hasMore}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>End of Log - no more data to display</b>
                  </p>
                }
                loader={<h4>Loading...</h4>}
              >
                {" "}
                <NotificationItemContainer notifItem={notifItem} />
              </InfiniteScroll>
            </div>
          </>
        }
      />
    </div>
  );
};

export default TopNavbar;
