import { Box, Grid, FormControl, Typography } from "@mui/material";
import { memo, useEffect, useMemo } from "react";
import CommonStyles from "../../../common/CommonStyles";
import PermissionsFormGroup from "./PermissionsCheckboxGroup";
import { roleLabels } from "../AddRole";
import { IState } from "../../../../redux/reducers/rootReducers";
import { toggleSysAdminCheckBoxes } from "../../../../redux/actions/userManagement/role";
import { connect } from "react-redux";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const CheckBoxList = memo((props:any) =>{
    const {indexVal,titleKey,permissions,checkedItems,dispatch} = props;
    const classes = CommonStyles();    
    const layout = useMemo(()=>{
        const selectAllHandler = (perms:any[]=[]) =>{
            dispatch(toggleSysAdminCheckBoxes({
                [titleKey] : perms.map((i:any)=>i.id)
            }))
        }
        const checked = permissions.length === (checkedItems[titleKey]?.length || 0)
        return(<>
        <Grid  item xs={6} md={12}>
            <div className={`p-1 ${indexVal % 2 === 1 ? '' : 'stripe'}`}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <Box>
                            <Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>
                                {roleLabels?.[titleKey] || titleKey}
                            </Typography>
                            <Typography className={`radio-label`} onClick={()=>{
                                // changeHandler(id,checked)
                                selectAllHandler(checked ? [] : permissions)
                                }}>
                                {checked ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                                <span>Select All</span>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <FormControl >
                            <PermissionsFormGroup titleKey={titleKey} permissions={permissions} />
                        </FormControl>
                    </Grid>

                </Grid>

            </div>
        </Grid>
        </>)
    },[checkedItems])
    return(<>
        {layout}
    </>)
})
const mapStateToProps = (state:IState) => {
    return ({
    //   checkedValues : state?.roleReducer?.checkedStatus,
      checkedItems : state?.roleReducer?.checkedStatus,
    //   state
    })
};
export default connect(mapStateToProps)(CheckBoxList);