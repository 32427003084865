import { useEffect, useState } from "react";
import NotificationItem from "./NotificationItem";
import { getNotifications } from "../../services/timeline/timeline.service";
import { Skeleton } from "@mui/material";


const NotificationItemContainer = ({notifItem}:{notifItem: any}) => {

  

  return (
    <>
      {notifItem?.length > 0 ? (
        notifItem?.map((item: any) => {
          return (
            <>
              <NotificationItem
              notifId={item?.id}
                title={item?.ticketId}
                desc={item?.message}
                time={item?.createdAt}
                url={item?.createdByUserProfilePath}
                taskId={item?.ticketTaskId}
                ticketId={item?.ticketId}
                isRead={item?.isRead}
              />
            </>
          );
        })
      ) : (
        <div
          style={{
            padding: "10px 10px 0px 10px",
            width: "100%",
            display: "flex",
            gap: 17,
            flexDirection: "column",
          }}
        >
          <Skeleton
            width="100%"
            variant="rounded"
            height={"30px"}
            animation="wave"
          ></Skeleton>{" "}
          <Skeleton
            width="100%"
            variant="rounded"
            height={"30px"}
            animation="wave"
          ></Skeleton>{" "}
          <Skeleton
            width="100%"
            variant="rounded"
            height={"30px"}
            animation="wave"
          ></Skeleton>{" "}
          <Skeleton
            width="100%"
            variant="rounded"
            height={"30px"}
            animation="wave"
          ></Skeleton>{" "}
        </div>
      )}
    </>
  );
};

export default NotificationItemContainer;
