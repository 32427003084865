import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getRandomColor } from '../horizontal-chart/VerticalChart';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DoughnutChart = ({
  labels,
  datasets,
  ref,
  id,
  showLegend = true,
  showLabels = true, 
  isDarkMode = false, 
}: {
  labels: any;
  datasets: any;
  ref?: any;
  id?: any;
  showLegend?: boolean;
  showLabels?: boolean; 
  isDarkMode?: boolean; 
}) => {
  const options: any = {
    maintainAspectRatio: false,
    cutoutPercentage: 90,
    plugins: {
      legend: {
        display: showLegend,
        position: 'top' as const,
        labels: {
          generateLabels: (chart: any) => {
            const { datasets, labels } = chart.data;
            return labels.map((label: any, index: any) => {
              const dataValue = datasets[0]?.data[index] || 0; // Get data value
              const backgroundColor = getRandomColor(); // Get color
              return {
                text: `${label} (${dataValue})`, // Include data count in legend label
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          color: !isDarkMode ? "black" : "white",
          usePointStyle: false, // Use regular legend markers (not point-style)
          font: {
            size: 14, // Adjust font size
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10, // Custom size for the legend box width (color box)
          boxHeight: 10, // Custom size for the legend box height
        },
      },
      datalabels: {
        display: true, // Always show values
        labels: {
          name: {
            align: 'top',
            // color: "black",
            color: !isDarkMode ? "black":"white",
            font: { size: 14 },
            formatter: (value: any, ctx: any) => {
              return showLabels && ctx.chart.data.datasets[0].data[ctx.dataIndex] > 0
                ? ctx.chart.data.labels[ctx.dataIndex]
                : '';
            },
          },
          value: {
            color: !isDarkMode ? "black":"white",
            font: { size: showLabels ? 7 : 9 },
            formatter: (value: any, ctx: any) => {
              return showLabels ? `${ctx.chart.data.datasets[0].data[ctx.dataIndex]}%` :`${ctx.chart.data.datasets[0].data[ctx.dataIndex]}`; // Always show values
            },
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return (
    <Doughnut
      ref={ref}
      data={data}
      options={options}
      id={id}
    />
  );
};

export default DoughnutChart;
