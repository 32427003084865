import axios from "axios";
import moment from "moment";
import {
  AddTicketTasktaskUrl,
  AddUserTaskUrl,
  getTicketsByFilterCSVUrl,
  getTicketsByFilterReportUrl,
  getTicketsByFilterUrl,
  getTicketsForCalendar,
  getTicketsUrl,
  getUserTicketsUrl,
  GroupGetAllUrl,
} from "../../../../src/services/dashboard/dashboard.service";
import { ConvertZ } from "../../../components/helpers/helperFunctions";
import configs from "../../../configs/config";
import LanguageData from "../../../configs/LanguageData.json";
import { assetGetUrl } from "../AssetsManagement/Assets";
import { userGroupInfoUrl } from "../Dashboard/insight";
import { contactAidUrl, triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import { ActionType } from "../Snackbars";
import { url as priorityUrl, storePriorityList } from "../SystemManagement/AddPriority";
import { url as TicketTypesUrl } from "../SystemManagement/ticket";
import { ticketSourcesurl, url as ticketStatusUrl } from "../SystemManagement/TicketStatus";
import { url as AgentsUrl, fileManagementUrl } from "../userManagement/agents";
import { url as groupsUrl } from "../userManagement/group";
import { toggleEmailPopup } from "./MobileTicketActions";
import { tasksToEvents, ticketsToEvents } from "./schema";
export const categoryByIdUrl = `${configs.BASE_URL_Ticket}/category/admintypes/id?id=`
export const attachmentGetUrl = `${configs.BASE_URL}/TicketLogAttachments`;
interface TicketLogAttachment {
  id?: number;
  ticketLogId?: number;
  attachmentId?: number;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  file?: any; // You can replace 'any' with the actual type of 'file' if known
}

interface Contacts {
  id?: number;
  name?: string;
  userName?: string;
  email?: string;
  knownAs?: string;
  isActive?: boolean;
  profilePictureId?: number;
  profilePicturePath?: null | string;
}
interface IBasicModal{
  id:number;
  name:string;
  displayName:string;
}
interface TicketLogs {
  id: number;
  customId?: any; // Replace 'any' with the actual type if known
  ticketId?: number;
  workflowNodeId?: number;
  type?: string;
  title?: string;
  note?: string;
  webformId: number;
  webform?:any;
  webformValues: string | any;
  description?: string;
  categoryId?: number;
  category?: IBasicModal | null ;
  statusId?: number;
  ticketTypeId?: number;
  priorityId?: number;
  urgency?: number;
  outgoingSourceCode?: null | string;
  sourceCode?: string;
  sla?: number;
  openDateTime?: string;
  dueDateTime?: string;
  firstResponseDueDateTime?: string;
  dueDateTimeFormatted?: string;
  firstResponseDueDateTimeFormatted?: string;
  scheduleDateTime?: string;
  createdDateTime?: string;
  createdByUserId?: number;
  isActive?: boolean;
  contactId?: number;
  secondaryContactId?: number;
  groupId?: number;
  agentId?: number;
  managerId?: number;
  nextActionDescription?: string;
  from?: null | string;
  to?: null | string;
  circulationListTO?: null | string;
  cc?: null | string;
  circulationListCC?: null | string;
  bcc?: null | string;
  circulationListBCC?: null | string;
  subject?: null | string;
  body?: null | string;
  isMessageSuccess?: boolean;
  user?: null | string;
  slaRemainingInTicks?: null | number;
  slaRemaining?: string;
  effortInTicks?: number;
  effort?: string;
  downtimeInTicks?: number;
  downtime?: string;
  ticketLogAttachmentsList?: TicketLogAttachment[];
  ticketLogAssetList?: any[]; // Replace 'any' with the actual type if known
  ticketLogChangesList?: any[] | null; // Replace 'any' with the actual type if known
  contacts?: Contacts; // Redis Value
  agent?: any; // Redis Value
  group?: any; // Redis Value
  secondaryContacts?: any;
  contactOrganisationId : number;
  thirdPartyRefNo: string;
  contactOrganisation?: any;
  priority?: any;
  ticketStatus?: any;
  ticketType?: any;
}

export interface ITicket {
  id?: number;
  category?:any;
  ticketId?:number;
  sourceCode?: string;
  workflowId?: any; // Replace 'any' with the actual type if known
  contactAidId?: number;
  createdDateTime?: string;
  isActive?: boolean;
  businessRuleIds?: string;
  ticketLogsId?: number;
  ticketLogs: TicketLogs;
  ticketTaskLogs?:TicketLogs;
  ticketAssetList?:any[];
  workflow?: any; // Replace 'any' with the actual type if known
  ticketLogsList?: any[] | null; // Replace 'any' with the actual type if known
  ticketRelationshipList?: any[]; // Replace 'any' with the actual type if known
  ticketTagList?: any[]; // Replace 'any' with the actual type if known
  ticketTaskList?: any[]; // Replace 'any' with the actual type if known
  attachments?: any[];
  attachmentDetails?: any[];
  assets?: any[];
  totalEffort?: any; // Replace 'any' with the actual type if known
}

export const formInfoData = (arr: any[] = [], configArr: any = []) => {
  const tempData: any = {};
  try {
    arr.map((i: any) => {
      if (!i.isActive) return;
      configArr.map((j: any) => {
        if (!tempData[j.name]) {
          tempData[j.name] = {};
        }
        const key = j.vals[0];
        const keyVal = j.vals?.[1] ? j.vals[1] : "id";
        tempData[j.name][i[keyVal]] = key === "" ? i : i[key];
      });
    });
  } catch (e) {
    console.log("formInfoData", e);
  }
  return tempData;
};
export const getAllGroup = (callback?: any, isSSP: boolean = false) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${GroupGetAllUrl}`);
      
      // Filter and transform the response data for isActive: true
      const activeGroups = response.data
        .filter((group: any) => group.isActive)
        .map((group: any) => ({
          value: group.name,
          label: group.name,
          ...group, // Include other properties if needed
        }));
        console.log("response.data", response.data,activeGroups);

      try {
        callback && callback("0", activeGroups);
      } catch (e) {
        console.error("Callback Error:", e);
      }
    } catch (error: any) {
      callback && callback("1", error.response);
      console.log(error.response);
    }
  };
};
const getUsrList = (userGroupList: any) => {
  let options: any = [];
  if (userGroupList?.map) {
    userGroupList.map((i: any) => {
      if (i?.agent && i?.agent?.isActive) options.push({
        ...i?.agent,
        iconLabel : getInitial(i?.agent?.firstName,i?.agent?.lastName),
      });
    });
  }
  options = getDropdownOptions(options, ["firstName", "lastName"]);
  return options;
};
export const getDropdownOptions = (arr: any, keys: any = ["displayName"]) => {
  let options: any = [];
  if (arr?.map) {
    arr.map((i: any) => {
      if (!i.isActive) return;
      let label = keys.map((key: any) => i?.[key]);
      let optionItem = {
        ...i,
        label: label[0] ? label.join(" ") : i?.name,
        value: i?.id,
        id: i.id,
      };
      options.push(optionItem);
    });
  }
  return options;
};
// const formInfoData = (arr:any, key:string='', keyVal:string | number = 'id') =>{
//   const tempData:any = {};
//   try{
//     arr.forEach((i:any)=>{
//       tempData[i[keyVal]] =  key === '' ? i : i[key];
//     })
//   }catch(e){console.log('formInfoData',e)}
//   return tempData;
// }
const modifyData = (arr: any, statusIds: any) => {
  // const userIds = otherUserIds.filter((x:any)=>x!=='a');
  // const {isSuperAdmin} = rolesAccess;
  const AllTickets: any = {
    MyNewTickets: [],
    NewTickets: [],
    AllCurrentTickets: [],
    AllTickets: [...arr],
  };
  arr.forEach((i: any) => {
    try {
      if (!i.ticketLogs) return;
      const { status } = i.ticketLogs;
      const statusToIgnore = [statusIds?.["Closed"],statusIds?.["Delete"], statusIds?.["New"]];
      if (statusIds?.["NewtoMe"] === status) {
        AllTickets.MyNewTickets.push(i);
      } else if (!statusToIgnore.includes(status)) {
        AllTickets.AllCurrentTickets.push(i);
      }
    } catch (e) {
      console.log(e, i);
    }
  });
  return AllTickets;
};
export const showErrMsg = (msg: string, type: string) => {
  return async (dispatch: any) => {
    dispatch({
      type: ActionType.SNACKBAR,
      payload: {
        message: msg,
        severity: type,
        open: true,
      },
    });
  };
};
export const storeAllCSVResponses = (data: any) => {
  return {
    type: "STORE_TICKETS_FOR_REPORT",
    payload: data
  };
};
export const storeAllResponses = (AllData: any) => {
  return {
    type: "STORE_ALL_RESPONSES",
    payload: { ...AllData, isLoaded: AllData?.isLoaded || false },
  };
};
export const storeUpdatedTicketResponses = (ticket: any) => {
  return { type: "UPDATE_TICKET_ON_LIST", payload: ticket };
};
export const saveQueryParam = (info: any) => {
  return { type: "STORE_QUERY_PARAM", payload: info };
};
export const addNewTicketTask = (request: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.post(AddTicketTasktaskUrl, request);
      if (callback) callback("0", response.data);
      dispatch(showErrMsg(`${LanguageData.TASK_ADDED_SUCCESSFULLY} ${response.data.ticketTaskLogs.ticketTaskId} ${LanguageData.TASK_CREATED} ${response.data.ticketId} `, "success"));
      console.log("rubesh",response)
    } catch (error) {
      dispatch(
        showErrMsg("Adding new task failed! Please try later.!", "error")
      );
      if (callback) callback("1", error);
      return console.log("err", error);
    }
  };
};
export const deleteTicketTask = (id: string | number, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.delete(`${AddTicketTasktaskUrl}/${id}`);
      if (callback) callback("0", response.data);
      dispatch(showErrMsg(LanguageData.TASK_DELETED_SUCESSFULLY, "success"));
    } catch (error) {
      dispatch(showErrMsg("Task delete failed! Please try later.!", "error"));
      return console.log("err", error);
    }
  };
};
export const storeAllTickets = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "STORE_ALL_TICKETS",
        payload: data,
      });
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};
export const storeAllNewTickets = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "STORE_ALL_NEW_TICKETS",
        payload: data,
      });
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};

export const newFilterAllTickets: any = (statusIds: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      let query = "";
      query = `?page=0&count=100`;
      dispatch(reloadAllDAshboardTickets(query, statusIds, callback));
      dispatch(getNewTickets(statusIds));
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};
const getNewTickets = (statusIds: any, queryUserIds?: string) => {
  return async (dispatch: any) => {
    try {
      console.log("statusIds", statusIds);
      if (!statusIds?.["New"]) {
        return;
      }
      const ticketsRes: any = await axios.get(
        `${getTicketsByFilterUrl}?page=0&count=100&statusIds=${statusIds?.["New"]}`
      );
      console.log("getNewTickets", ticketsRes.data, statusIds);
      const modifiedData: any = {
        NewTickets: ticketsRes.data,
      };

      dispatch(storeAllNewTickets(modifiedData));
      //storeAllNewTickets
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};
export const getInitial = (firstName:string="",secondName:string="") =>{
  let initial = "";
  if(secondName.trim() !== ""){
    initial = firstName.substring(0,1) + secondName.substring(0,1)
  }else{
    initial = firstName.substring(0,2)
  }
  // console.log("getInitial",initial)
  return initial.toUpperCase();
}
export const updateAllResponseStruct = (val:any) =>{
  return {
    type:"UPDATE_ALL_STRUCTURES",
    payload : val
  }
}
export const updateAllResponseList = (val:any) =>{
  return {
    type:"UPDATE_ALL_RESPONSES",
    payload : val
  }
}
const asyncCallback = (allResponse: any, callback?: any) => {
  return (dispatch: any) => {
    try {
      console.log("SDTIME", new Date());
      console.log("allResponse>>", allResponse);
      let tempAllInfo: any = {};
      const AllData: any = {};
      AllData.agentsPerRole = [];
      AllData.groups = [];
      allResponse[0].data.filter((i: any) => {
        if (i.userGroupList) {
          i.userGroupList.map((j: any) => {
            if (
              i.isActive &&
              j?.agent?.isActive &&
              !AllData.agentsPerRole.some((k: any) => k.id === j.agent.id)
            ) {
              AllData.agentsPerRole.push({
                ...j.agent,
                name: `${j.agent?.firstName} ${j.agent?.lastName}`,
                value: j?.agent?.id || 0,
                label: `${j.agent?.firstName} ${j.agent?.lastName}`,
              });
            }
          });
        }
        if (i.isActive)
          AllData.groups.push({
            ...i,
            label: i?.name,
            value: i?.id,
            agentsList: getUsrList(i?.userGroupList),
          });
      });
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(AllData.groups, [{ name: "groups", vals: ["name"] }]),
      };

      AllData.priorities = getDropdownOptions(allResponse[1].data, ["name"]);
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(AllData.priorities, [
          { name: "priority", vals: ["name"] },
          { name: "priorityTypesById", vals: ["", "id"] },
        ]),
      };
      dispatch(storePriorityList({data:AllData.priorities}));

      AllData.ticketSources = getDropdownOptions(allResponse[2].data, ["code"]); //allResponse[2].data.map((i:any)=>{return {...i,"isActive":true} });//[]//;
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(AllData.ticketSources, [
          { name: "sources", vals: ["code"] },
          { name: "sourceTypes", vals: ["id", "code"] },
        ]),
      };

      const statusArr = getDropdownOptions(allResponse[3].data);
      AllData.ticketTaskStatus = []
      AllData.ticketStatus = statusArr.filter((x:{type:string})=>{
        if(x.type !== 'TicketStatus'){
          AllData.ticketTaskStatus.push(x)
        }
        return x.type === 'TicketStatus'
      })
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(allResponse[3].data, [
          { name: "status", vals: ["displayName"] },
          { name: "statusNames", vals: ["name"] },
          { name: "statusTypes", vals: ["id", "name"] },
          { name: "statusTypesById", vals: ["", "id"] },
        ]),
      };

     

      AllData.TicketTypes = getDropdownOptions(allResponse[4].data);
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(AllData.TicketTypes, [
          { name: "TicketTypes", vals: ["displayName"] },
        ]),
      };

      AllData.AgentsList = getDropdownOptions(allResponse[5].data, [
        "firstName",
        "lastName",
      ]);
      tempAllInfo = {
        ...tempAllInfo,
        ...formInfoData(AllData.AgentsList, [
          { name: "Agents", vals: ["userName"] },
        ]),
      };
      AllData.contactAidList = getDropdownOptions(allResponse[6].data);
      dispatch(storeAllResponses({ ...AllData, isLoaded: true }));
      console.log("STORE_ALL_STRUCTURES",AllData)
      dispatch({ type: "STORE_ALL_STRUCTURES", payload: tempAllInfo });
      if (callback) callback(tempAllInfo.statusTypes, AllData.groups);
    } catch (e) {
      console.log("***", e);
    }
  };
};
export const getAllDAshboardData = (callback?: any) => {
  return async (dispatch: any) => {
    try {
      const APIS: any = [
        groupsUrl,
        priorityUrl,
        ticketSourcesurl,
        `${ticketStatusUrl}`,
        TicketTypesUrl,
        AgentsUrl,
        contactAidUrl
      ]; //,OrgUrl-->getTicketsByFilterUrl
      Promise.all(
        APIS.map((endpoint: string, index: number) => axios.get(`${endpoint}`))
      ).then((all) => {
        console.log("getAllDAshboardData>>", all);
        dispatch(asyncCallback(all, callback));
      });
    } catch (e) {
      console.log("get all data", e);
      if (callback) callback();
    }
  };
};
export const reloadAllDAshboardTickets = (
  query: string = "",
  statusIds: any = {},
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const ticketsRes: any = await axios.get(
        `${getTicketsByFilterUrl}${query}`
      );
      dispatch(storeAllTickets(modifyData(ticketsRes.data, statusIds)));
      if (callback) callback();
    } catch (e: any) {
      console.log("reloadAllDAshboardTickets", e.response);
      if (callback) callback();
    }
  };
};
export const storeModifiedData = (tickets: any, statusIds: any = {}) => {
  return async (dispatch: any) => {
    try {
      dispatch(storeAllTickets(modifyData(tickets, statusIds)));
    } catch (e: any) {
      console.log("storeModifiedData", e.response);
    }
  };
};

export const customAPiCall = async (ids: string = "") => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${fileManagementUrl}`);
      console.log(response);
    } catch (e: any) {
      console.log("customAPiCall", e.response);
    }
  };
};
export const downloadMultiFilesById = (
  ids: string = "",
  callback?: any,
  page: string = ""
) => {
  return async (dispatch: any) => {
    try {
      if (ids === "") return;
      const response = await axios(`${fileManagementUrl}ids?ids=${ids}`);
      if (page !== "") {
        console.log(response.data);
        const tempAllInfo = {
          ...formInfoData(response.data, [
            { name: "contactsImg", vals: ["filePath"] },
          ]),
        };
        console.log("ids-response", response, tempAllInfo);
        dispatch({ type: "STORE_CONTACT_IMAGES", payload: tempAllInfo });
      }
      try {
        if (callback) callback(response.data);
      } catch (e: any) {}
    } catch (e: any) {
      console.log("downloadMultiFilesById", e.response);
    }
  };
};
export const storeQueryParam = (paramValue: string) => {
  return async (dispatch: any) => {
    try {
      dispatch(saveQueryParam(paramValue));
    } catch (e: any) {
      console.log("storeQueryParam", e.response);
    }
  };
};
export const reportTickets = (
  startDate: any = "",
  endDate: any = "",
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const ticketsRes: any = await axios.get(
        `${getTicketsByFilterUrl}?page=0&count=100&startDate=${startDate}&endDate=${endDate}`
      );
      if (callback) callback("0", ticketsRes.data);
    } catch (e: any) {
      console.log("reloadAllDAshboardTickets", e.response);
      if (callback) callback("1", e.response);
    }
  };
};
export const getCategoryByID = (id: number , callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.get(`${categoryByIdUrl}${id}`);
      console.log("response", response);
      dispatch({ type: "GET_CATEGORY_BY_ID", payload: response.data });
      callback(response.data);
    } catch (error: any) {
      console.log("error", error.response);
    }
  };
};
export const getTicketByID = (id: number | string = "", callback?: any,ignoreLoadingAssets:boolean=true) => {
  return async (dispatch: any) => {
    try {
      const postApiCalls = (data: any) => {
        // dispatch({ type: "ALL_TICKETS_BY_ID", payload: data });
        try {
          if (callback) callback("0", data);
        } catch (e) {
          console.log("getTicketByID", e);
        }
      };

      const ticketsRes: any = await axios.get(`${getTicketsUrl}/${id}`);
      if(ticketsRes?.data?.ticketLogs?.categoryId){
        const categoryObj: any = await axios.get(`${categoryByIdUrl}${ticketsRes?.data?.ticketLogs?.categoryId}`);
        ticketsRes.data.ticketLogs.category = categoryObj?.data?.name ? { "label": categoryObj?.data?.name, "name": categoryObj?.data?.name, "id": ticketsRes?.data?.ticketLogs?.categoryId} : null
        console.log("categoryObj",categoryObj?.data)
      }

      ticketsRes.data.attachments = [];
      ticketsRes.data.assets = [];
      
      const attachmentRes: any = await axios.get(`${attachmentGetUrl}/${id}`);
            ticketsRes.data.attachmentDetails = attachmentRes?.data || {};
      if (
        ignoreLoadingAssets &&(
        ticketsRes?.data?.ticketAssetList?.length ||
        ticketsRes?.data?.ticketAssetList.length > 0)
      ) {
        const fileIds = ticketsRes.data.ticketAssetList.map(
          (i: any) => i.assetId
        );
        Promise.all(
          fileIds.map((id: number) => axios.get(`${assetGetUrl}/${id}`))
        ).then((all: any) => {
          ticketsRes.data.assets = all.map((i: { data: any }) => i.data);
          postApiCalls(ticketsRes.data);
        });
      } else {
        console.log("categoryObj1",'categoryObj?.data')
        postApiCalls(ticketsRes.data);
      }
    } catch (e: any) {
      console.log("getTicketByID", e.response);
      dispatch(showErrMsg(e?.response?.data, "error"));
      if (callback) callback("1", e.response);
    }
  };
};
 
export const getTicketByContact = (req: any = {}, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const endDateVal = new Date();
      const currentTime = new Date(`${endDateVal.getFullYear() - 1}`);
      currentTime.setHours(0);
      endDateVal.setHours(23);
      const payload = {
        startDate: currentTime,
        endDate: endDateVal,
        search: "",
        contactIds: [],
        agentIds: [],
        groupIds: [],
        ticketTypeIds: [],
        priorityIds: [],
        statusIds: [],
        sortPropString: "OpenDateTime",
        ticketFilterPropString: "CreatedDateTime",
        sortTypeString: "Desc",
        page: 0,
        count: 100,
        ...req,
      };
      const ticketsRes: any = await axios.post(
        `${getUserTicketsUrl}/filters`,
        payload
      );
      console.log("ticketsResticketsRes",ticketsRes);
      if(!ticketsRes.data.map) {
        dispatch({
          type: "STORE_CONTACT_TICKETS",
          payload: [],
        });  
        if (callback) callback("0", []);
        return;
      }
      const allTickets = ticketsRes.data.map((ticket: any) => {
          ticket.createdDateTimeFormatted = ConvertZ(ticket.createdDateTime);
          if (ticket.ticketLogs) {
            ticket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ticket.ticketLogs.scheduleDateTime);
            ticket.ticketLogs.openDateTimeFormatted = ConvertZ(ticket.ticketLogs.openDateTime);
            ticket.ticketLogs.firstResponseDueDateTimeFormatted = ConvertZ(ticket.ticketLogs.firstResponseDueDateTime);
            ticket.ticketLogs.dueDateTimeFormatted = ticket.ticketLogs.dueDateTime ?ConvertZ(ticket.ticketLogs.dueDateTime):null
          }
          return ticket;
      });
      dispatch({
        type: "STORE_CONTACT_TICKETS",
        payload: allTickets,
      });
      if (callback) callback("0", ticketsRes.data);
    } catch (e: any) {
      console.log("getTicketByID", e.response);
      if (callback) callback("1", e.response);
    }
  };
};
export const getTicketByContactUserId = (
  contactId: number,
  callback?: any,
  searchVal?: string
) => {
  return async (dispatch: any) => {
    try {
      const endDateVal = new Date();
      const currentTime = new Date(`${endDateVal.getFullYear() - 1}`);
      currentTime.setHours(0);
      endDateVal.setHours(23);
      const payload = {
        startDate: currentTime,
        endDate: endDateVal,
        search: searchVal || "",
        contactIds: contactId ? [contactId] : [],
        agentIds: [],
        groupIds: [],
        ticketTypeIds: [],
        priorityIds: [],
        statusIds: [],
        sortPropString: "OpenDateTime",
        ticketFilterPropString: "OpenDateTime",
        sortTypeString: "Desc",
        page: 0,
        count: 100,
      };

      const ticketsRes: any = await axios.post(
        `${getUserTicketsUrl}/filters`,
        payload
      );
      const allTickets = ticketsRes.data.map((ticket: any) => {
          ticket.createdDateTimeFormatted = ConvertZ(ticket.createdDateTime);
          if (ticket.ticketLogs) {
            ticket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ticket.ticketLogs.scheduleDateTime);
            ticket.ticketLogs.openDateTimeFormatted = ConvertZ(ticket.ticketLogs.openDateTime);
            ticket.ticketLogs.firstResponseDueDateTimeFormatted = ConvertZ(ticket.ticketLogs.firstResponseDueDateTime);
            ticket.ticketLogs.dueDateTimeFormatted = ticket.ticketLogs.dueDateTime ?ConvertZ(ticket.ticketLogs.dueDateTime):null

          }
          return ticket;
      });
      dispatch({
        type: "STORE_CONTACT_TICKETS",
        payload: allTickets,
      });
      if (callback) callback("0", ticketsRes.data || []);
    } catch (e: any) {
      console.log("getTicketByID", e.response);
      if (callback) callback("1", e.response);
    }
  };
};
export const storeFilters = (value: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({
        type: "STORE_FILTER",
        payload: value,
      });
    } catch (e) {}
  };
};
export const multiApiCalls = (
  arr: any[] = [],
  keys: any[] = [],
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      Promise.all(
        arr.map((endpoint: string, index: number) => axios.get(`${endpoint}`))
      ).then((all: any) => {
        console.log("multiApiCalls", all);
        if (callback) {
          let resObj: any = {};
          keys.map((i: any, index: number) => {
            resObj[i] = all?.[index]?.data;
          });
          callback("0", resObj);
        }
      });
      //customAPiCall(rolesAccess,currAgent,callback);
    } catch (e) {
      console.log("get all data", e);
      if (callback) callback("1", e);
    }
  };
};

// ==================== NEW PERFORMANCE FIXES ===============

const generateQuery = (queryVal: any, allData: any) => {
  const tempObj: any = {};
  const { ticketStatus = [] } = allData;
  /* storing status IDs and groups Ids*/
  tempObj.newTicketIds = [];
  tempObj.newtoMeTicketIds = [];
  tempObj.withoutCloseTickets = [];
  ticketStatus.map((i: any) => {
    if(i.name === "Delete") return;
    if (i.name === "New") {
      tempObj.newTicketIds.push(i.id);
    } else if (i.name === "NewtoMe") {
      tempObj.newtoMeTicketIds.push(i.id);
    } else if (i.name !== "Closed" && queryVal?.statuses?.length === 0) {
      tempObj.withoutCloseTickets.push(i.id);
    }
  });
  console.log("allDataallDataallDataallData",allData);
  
  tempObj.allGroupIds = allData?.groups?.map
    ? allData?.groups.map((i: any) => i.id)
    : [];
  return tempObj;
};

const generateQueryForReport = (queryVal: any, allData: any) => {
  const tempObj: any = {};
  const { ticketStatus = [] } = allData;
  /* storing status IDs and groups Ids*/
  tempObj.newTicketIds = [];
  tempObj.newtoMeTicketIds = [];
  tempObj.withoutCloseTickets = [];
  tempObj.allStatusIds = [];
  ticketStatus.map((i: any) => {
    tempObj.allStatusIds.push(i.id);
    if(i.name === "Delete") return;
    if (i.name === "New") {
      tempObj.newTicketIds.push(i.id);
    } else if (i.name === "NewtoMe") {
      tempObj.newtoMeTicketIds.push(i.id);
    } else if (i.name !== "Closed" && queryVal?.statuses?.length === 0) {
      tempObj.withoutCloseTickets.push(i.id);
    }
  });
  tempObj.allGroupIds = allData?.groups
    ? allData?.groups.map((i: any) => i.id)
    : [];
  return tempObj;
};

/* ========== GENERATE PAYLOAD ========== */
const generatePayload = (queryVal: any) => {
  const tempObj: any = {
    agentGroupObj: {
      agents: [],
      groups: [],
    },
    payload: {},
  };
  try {
    const { sortType, agentsWithGroup } = queryVal;

    if (agentsWithGroup?.length > 0) {
      for (let i of agentsWithGroup) {
        if (i.agentId !== 0 &&
          !tempObj.agentGroupObj.agents.includes(i.agentId)
        )
          tempObj.agentGroupObj.agents.push(i.agentId);
        if (!tempObj.agentGroupObj.groups.includes(i.groupId))
          tempObj.agentGroupObj.groups.push(i.groupId);
      }
    }

    const { node, endDate, startDate } = queryVal.rangeVal || {};
    // console.log("subMonths_subMonths",subMonths(new Date(), 1),isFirstDayOfMonth(new Date()))
    let endDateTime = endDate || new Date();
    let startDateTime = startDate || new Date(`${endDateTime.getFullYear() - 1}`);
    startDateTime = new Date (Date.UTC(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(),0,0,0,0)).toISOString();
    endDateTime = new Date (Date.UTC(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(),23,59,59,59)).toISOString();
    const sortVal = sortType
      .replaceAll("&sortprop=", "")
      .replaceAll("sorttype=", "")
      .split("&");
    tempObj.payload = {
      startDate: startDateTime,
      endDate: endDateTime,
      search: "",
      contactIds: [],
      agentIds: [],
      groupIds: [],
      ticketTypeIds: [],
      priorityIds: [],
      statusIds: [],
      sortPropString: sortType === "unset" ? "OpenDateTime" : sortVal[0],
      ticketFilterPropString: node || "OpenDateTime",
      sortTypeString: sortType === "unset" ? "Desc" : sortVal[1],
      page: 0,
      count: 100,
    };
    return tempObj;
  } catch (error) {
    console.log("generatePayloadERR", error);
    return tempObj;
  }
};
export const quickUpdateTicket = (apiPayload: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.put(
        `${getTicketsUrl}/${apiPayload.id}`,
        apiPayload
      );
      const getTicketById = await axios(`${getTicketsUrl}/${apiPayload.id}`);
      dispatch(storeUpdatedTicketResponses({
        ...getTicketById.data,
        ticketLogs : {
          ...getTicketById?.data?.ticketLogs,
          scheduleDateTimeFormatted : ConvertZ(getTicketById.data?.ticketLogs.scheduleDateTime),
          openDateTimeFormatted : ConvertZ(getTicketById.data?.ticketLogs.openDateTime),
          firstResponseDueDateTimeFormatted : ConvertZ(getTicketById.data?.ticketLogs.firstResponseDueDateTime),
          dueDateTimeFormatted : getTicketById.data?.ticketLogs.dueDateTime ? ConvertZ(getTicketById.data?.ticketLogs.dueDateTime):null,
        }
      }));
      console.log("quickUpdateTicket", response, getTicketById);
      dispatch(triggerAppAlert(`Success! Ticket ID ${apiPayload.id} updated`, 'success'))
      if (callback) callback(response.data);
    } catch (error: any) {
      if (callback) callback("failure",error.response);
      if(error?.response){
        dispatch(triggerAppAlert(`Ticket updation failed! Please try again`, 'delete'))
      }
      return error.response;
    }
  };
};
export const getAllTickets = (
  queryVal: any,
  allData: any,
  AllInfo?: any,
  callback?: any,
  report?: any
) => {
  return async (dispatch: any) => {
    try {
      const {
        searchVal = "",
        contacts,
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
      } = queryVal;
      const { payload, agentGroupObj }: any = generatePayload(queryVal);
      const {
        newTicketIds,
        newtoMeTicketIds,
        allGroupIds,
        withoutCloseTickets,
      } = generateQuery(queryVal, allData);

      const queries = [
        {
          ...payload,
          statusIds: newTicketIds,
          groupIds: [0, ...allGroupIds],
          search: newTicketIds.length === 0 ? `${Math.random()}` : searchVal,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        {
          // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
          ...payload,
          statusIds: newtoMeTicketIds,
          search:
            newtoMeTicketIds.length === 0 ? `${Math.random()}` : searchVal, //searchVal,
          ticketTypeIds: ticketTypes,
          groupIds:
            agentsWithGroup?.length > 0
              ? agentGroupObj.groups
              : [...allGroupIds],
          agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
        {
          // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
          ...payload,
          statusIds: statuses.length === 0 ? withoutCloseTickets : statuses,
          groupIds:
            agentsWithGroup?.length > 0
              ? agentGroupObj.groups
              : [...allGroupIds],
          agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
          search:
            statuses.length === 0 && withoutCloseTickets.length === 0
              ? `${Math.random()}`
              : searchVal, // searchVal,
          ticketTypeIds: ticketTypes,
          startDate: payload.startDate,
          endDate: payload.endDate,
          contactIds: [],
          priorityIds: priorities,
        },
      ];
      dispatch({ type: "SHOW_LOADER", payload: true });
      Promise.all(
        queries.map((query: any, index: number) =>
          axios.post(
            `${report ? getTicketsByFilterReportUrl : getTicketsByFilterUrl}`,
            query
          )
        )
      )
        .then((all) => {
          // const allTickets = all.map((i: any) => i.data);
          const allTickets = all.map((i: any) => {
            return i.data.map((ticket: any) => {
              if (ticket.ticketLogs) {
                ticket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ticket.ticketLogs.scheduleDateTime);
                ticket.ticketLogs.openDateTimeFormatted = ConvertZ(ticket.ticketLogs.openDateTime);
                ticket.ticketLogs.firstResponseDueDateTimeFormatted = ConvertZ(ticket.ticketLogs.firstResponseDueDateTime);
                ticket.ticketLogs.dueDateTimeFormatted = ticket.ticketLogs.dueDateTime ? ConvertZ(ticket.ticketLogs.dueDateTime):null
              }
              if (ticket.ticketRelationshipList.length > 0) {
                console.log("ticketRelationshipList",ticket.ticketRelationshipList)
                ticket.ticketRelationshipList.map((ele:any)=>{
                    ele.childTicket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ele.childTicket.ticketLogs.scheduleDateTime);
                    ele.childTicket.ticketLogs.openDateTimeFormatted = ConvertZ(ele.childTicket.ticketLogs.openDateTime);
                    ele.childTicket.ticketLogs.firstResponseDueDateTimeFormatted = ele.childTicket.ticketLogs.firstResponseDueDateTime ? ConvertZ(ele.childTicket.ticketLogs.firstResponseDueDateTime) :null
                    ele.childTicket.ticketLogs.dueDateTimeFormatted = ele.childTicket.ticketLogs.dueDateTime ? ConvertZ(ele.childTicket.ticketLogs.dueDateTime):null
                })
              }
              return ticket;
            });
          });
          dispatch({ type: "SAVE_ALL_TICKETS", payload: allTickets });
          dispatch({ type: "SHOW_LOADER", payload: false });
          if(callback) callback("0",allTickets)
        })
        .catch((e) => {
          console.log("ticketsRes12345", e);
          dispatch({ type: "SHOW_LOADER", payload: false });
          dispatch(
            showErrMsg(
              "we're facing technical difficulties! Please contact helpdesk.",
              "warning"
            )
          );
        });
    } catch (error: any) {
      if (callback) callback("1");
      console.log(error.response);
      return error.response;
    }
  };
};

export const getAllTicketsMobile = (
  queryVal: any,
  allData: any,
  callback?: any,
  report?: any
) => {
  return async (dispatch: any) => {
    try {
      console.log("queriespayload",queryVal);

      const {
        searchVal = "",
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
        rangeVal
      } = queryVal;
      const { payload, agentGroupObj }: any = generatePayload(queryVal);
      const {
        newTicketIds,
        newtoMeTicketIds,
        allGroupIds,
        withoutCloseTickets=[],
      } = generateQuery(queryVal, allData);
      
      const otherStatusIds = statuses.length === 0 ? [...newTicketIds, ...newtoMeTicketIds, ...withoutCloseTickets] : statuses;
      
      console.log("queriespayload",payload,queryVal);
      
      const queries = [
        {
          ...payload,
          statusIds: otherStatusIds,
          groupIds:
            agentsWithGroup?.length > 0
              ? agentGroupObj.groups
              : [0,...allGroupIds],
          agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
          search:
            statuses.length === 0 && withoutCloseTickets.length === 0
              ? `${Math.random()}`
              : searchVal, // searchVal,
          ticketTypeIds: ticketTypes,
          startDate: rangeVal.startDate,
          endDate: rangeVal.endDate,
          contactIds: [],
          priorityIds: priorities,
          count: 150,
        },
      ];
      console.log("queriesqueriesqueries",queries);
      
      dispatch({ type: "SHOW_LOADER", payload: true });
      Promise.all(
        queries.map((query: any, index: number) =>
          axios.post(
            `${report ? getTicketsByFilterReportUrl : getTicketsByFilterUrl}`,
            query
          )
        )
      )
        .then((all) => {
          // const allTickets = all.map((i: any) => i.data);
          const allTickets = all.map((i: any) => {
            return i.data.map((ticket: any) => {
              if (ticket.ticketLogs) {
                ticket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ticket.ticketLogs.scheduleDateTime);
                ticket.ticketLogs.openDateTimeFormatted = ConvertZ(ticket.ticketLogs.openDateTime);
                ticket.ticketLogs.firstResponseDueDateTimeFormatted = ConvertZ(ticket.ticketLogs.firstResponseDueDateTime);
                ticket.ticketLogs.dueDateTimeFormatted = ticket.ticketLogs.dueDateTime ? ConvertZ(ticket.ticketLogs.dueDateTime):null
              }
              if (ticket.ticketRelationshipList.length > 0) {
                console.log("ticketRelationshipList",ticket.ticketRelationshipList)
                ticket.ticketRelationshipList.map((ele:any)=>{
                    ele.childTicket.ticketLogs.scheduleDateTimeFormatted = ConvertZ(ele.childTicket.ticketLogs.scheduleDateTime);
                    ele.childTicket.ticketLogs.openDateTimeFormatted = ConvertZ(ele.childTicket.ticketLogs.openDateTime);
                    ele.childTicket.ticketLogs.firstResponseDueDateTimeFormatted = ele.childTicket.ticketLogs.firstResponseDueDateTime ? ConvertZ(ele.childTicket.ticketLogs.firstResponseDueDateTime) :null
                    ele.childTicket.ticketLogs.dueDateTimeFormatted = ele.childTicket.ticketLogs.dueDateTime ? ConvertZ(ele.childTicket.ticketLogs.dueDateTime):null
                })
              }
              return ticket;
            });
          });
          dispatch({ type: "SAVE_ALL_TICKETS", payload: [[],[],...allTickets] });
          dispatch({ type: "SHOW_LOADER", payload: false });
          if(callback) callback("0",allTickets)
        })
        .catch((e) => {
          console.log("ticketsRes12345", e);
          dispatch({ type: "SHOW_LOADER", payload: false });
          dispatch(
            showErrMsg(
              "we're facing technical difficulties! Please contact helpdesk.",
              "warning"
            )
          );
        });
    } catch (error: any) {
      if (callback) callback("1");
      console.log(error.response);
      return error.response;
    }
  };
};

export const getAllCalendarTickets = (
  queryVal: any,
  allData: any,
  callback?: any,
) => {
  return async (dispatch: any) => {
    try {
      const {
        searchVal = "",
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
      } = queryVal;
      const { payload, agentGroupObj }: any = generatePayload(queryVal);
      const {
        allGroupIds,
        withoutCloseTickets,
      } = generateQuery(queryVal, allData);

      const query =  {
          // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
          ...payload,
          statusIds: statuses.length === 0 ? withoutCloseTickets : statuses,
          groupIds:
            agentsWithGroup?.length > 0
              ? agentGroupObj.groups
              : [...allGroupIds],
          agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
          search:
            statuses.length === 0 && withoutCloseTickets.length === 0
              ? `${Math.random()}`
              : searchVal, // searchVal,
          ticketTypeIds: ticketTypes,
          startDate: payload.startDate,
          endDate: payload.endDate,
          contactIds: [],
          priorityIds: priorities,
      };
      dispatch({ type: "SHOW_LOADER", payload: true });
      const response:any = await axios.post(getTicketsForCalendar,query);
      const {ticketList,ticketTaskList} = response.data;
      const itemsToStore = [...ticketsToEvents(ticketList),...tasksToEvents(ticketTaskList)];
      console.log("SAVE_ALL_CALENDAR_TICKETS",itemsToStore)
      dispatch({ type: "SAVE_ALL_CALENDAR_TICKETS", payload: itemsToStore});
      dispatch({ type: "SHOW_LOADER", payload: false });
    } catch (error: any) {
      if (callback) callback("1");
      console.log(error.response);
      return error.response;
    }
  };
};


const generatePayloadForReport = (queryVal: any) => {
  const tempObj: any = {
    agentGroupObj: {
      agents: [],
      groups: [],
    },
    payload: {},
  };
  try {
    const { sortType, agentsWithGroup } = queryVal;

    if (agentsWithGroup?.length > 0) {
      for (let i of agentsWithGroup) {
        if (
          i.agentId !== 0 &&
          !tempObj.agentGroupObj.agents.includes(i.agentId)
        )
          tempObj.agentGroupObj.agents.push(i.agentId);
        if (!tempObj.agentGroupObj.groups.includes(i.groupId))
          tempObj.agentGroupObj.groups.push(i.groupId);
      }
    }

    const { node, endDate, startDate } = queryVal.rangeVal || {};
    // console.log("subMonths_subMonths",subMonths(new Date(), 1),isFirstDayOfMonth(new Date()))
  
    let endDateTime = endDate || new Date();
    let startDateTime = startDate || new Date(`${endDateTime.getFullYear() - 1}`);
    startDateTime = new Date (Date.UTC(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(),0,0,0,0)).toISOString();
    endDateTime = new Date (Date.UTC(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(),23,59,59,59)).toISOString();

    const sortVal = sortType
      .replaceAll("&sortprop=", "")
      .replaceAll("sorttype=", "")
      .split("&");
    tempObj.payload = {
      startDate: startDateTime,
      endDate: endDateTime,
      search: "",
      contactIds: [],
      agentIds: [],
      groupIds: [],
      ticketTypeIds: [],
      priorityIds: [],
      statusIds: [],
      sortPropString: sortType === "unset" ? "OpenDateTime" : sortVal[0],
      ticketFilterPropString: node || "OpenDateTime",
      sortTypeString: sortType === "unset" ? "Desc" : sortVal[1],
      page: 0,
      count: 10000,
    };
    return tempObj;
  } catch (error) {
    console.log("generatePayloadERR", error);
    return tempObj;
  }
};

export const getAllTicketsForRepeorts = (
  queryVal: any,
  allData: any,
  AllInfo?: any,
  callback?: any,
  report?: any
) => {
  return async (dispatch: any) => {
    try {
      const {
        searchVal = "",
        contacts,
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
      } = queryVal;
      const { payload, agentGroupObj }: any =
        generatePayloadForReport(queryVal);
      const {
        newTicketIds,
        newtoMeTicketIds,
        allGroupIds,
        withoutCloseTickets,
        allStatusIds
      } = generateQueryForReport(queryVal, allData);

      const urlArray = ["count", "sum"]

      const queries = [{
        // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
        ...payload,
        statusIds: statuses.length === 0 ? allStatusIds : statuses,
        groupIds:
          agentsWithGroup?.length > 0 ? agentGroupObj.groups : [...allGroupIds],
        agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
        search:
          statuses.length === 0 && withoutCloseTickets.length === 0
            ? `${Math.random()}`
            : searchVal, // searchVal,
        ticketTypeIds: ticketTypes,
        startDate: payload.startDate,
        endDate: payload.endDate,
        contactIds: [],
        priorityIds: priorities,
      },{
        // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
        ...payload,
        statusIds: statuses.length === 0 ? allStatusIds : statuses,
        groupIds:
          agentsWithGroup?.length > 0 ? agentGroupObj.groups : [...allGroupIds],
        agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
        search:
          statuses.length === 0 && withoutCloseTickets.length === 0
            ? `${Math.random()}`
            : searchVal, // searchVal,
        ticketTypeIds: ticketTypes,
        startDate: payload.startDate,
        endDate: payload.endDate,
        contactIds: [],
        priorityIds: priorities,
      }];

      dispatch({ type: "SHOW_LOADER", payload: true });

      Promise.all(
        queries.map((query: any, index: number) =>
          axios.post(`${getTicketsByFilterReportUrl}/${urlArray[index]}`, query)
        )
      )
        .then((all) => {
          const allTickets = all.map((i: any) => i.data);
          console.log("allTickets then", allTickets)
          dispatch({ type: "STORE_ALL_TICKETS_FOR_REPORT", payload: allTickets[0] });
          dispatch({ type: "STORE_ALL_TICKETS_FOR_REPORT_SUM", payload: allTickets[1] });
          dispatch({ type: "SHOW_LOADER", payload: false });
        })

        .catch((e) => {
          console.log("ticketsRes12345", e);
          dispatch({ type: "SHOW_LOADER", payload: false });
          dispatch(
            showErrMsg(
              "we're facing technical difficulties! Please contact helpdesk.",
              "warning"
            )
          );
        });
      // if(callback) callback("0")
    } catch (error: any) {
      if (callback) callback("1");
      console.log(error.response);
      return error.response;
    }
  };
};
export const getAllTicketsForCSV = (
  queryVal: any,
  allData: any,
  AllInfo?: any,
  callback?: any,
  report?: any
) => {
  return async (dispatch: any) => {
    try {
      const {
        searchVal = "",
        contacts,
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
      } = queryVal;
      const { payload, agentGroupObj }: any =
        generatePayloadForReport(queryVal);
      const {
        newTicketIds,
        newtoMeTicketIds,
        allGroupIds,
        withoutCloseTickets,
        allStatusIds
      } = generateQueryForReport(queryVal, allData);

      const urlArray = ["count", "sum"]

      const queries = [{
        // NewtoMe - StatusIds, SearchValue, TicketType, AgentIds, Date Range
        ...payload,
        statusIds: statuses.length === 0 ? allStatusIds : statuses,
        groupIds:
          agentsWithGroup?.length > 0 ? agentGroupObj.groups : [...allGroupIds],
        agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
        search:
          statuses.length === 0 && withoutCloseTickets.length === 0
            ? `${Math.random()}`
            : searchVal, // searchVal,
        ticketTypeIds: ticketTypes,
        startDate: payload.startDate,
        endDate: payload.endDate,
        contactIds: [],
        priorityIds: priorities,
      }];

      dispatch({ type: "SHOW_LOADER", payload: true });

      Promise.all(
        queries.map((query: any, index: number) =>
          axios.post(`${getTicketsByFilterCSVUrl}`, query)
        )
      )
        .then((all) => {
          const allTickets = all.map((i: any) => i.data);
          console.log("allTickets then", allTickets)
          dispatch({ type: "STORE_ALL_TICKETS_FOR_CSV", payload: allTickets[0] });
          dispatch({ type: "SHOW_LOADER", payload: false });
        })

        .catch((e) => {
          console.log("ticketsRes12345", e);
          dispatch({ type: "SHOW_LOADER", payload: false });
          dispatch(
            showErrMsg(
              "we're facing technical difficulties! Please contact helpdesk.",
              "warning"
            )
          );
        });
      // if(callback) callback("0")
    } catch (error: any) {
      if (callback) callback("1");
      console.log(error.response);
      return error.response;
    }
  };
};

export const loadOtherTickets = (
  queryVal: any,
  allData: any,
  pageNo: any,
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const {
        searchVal = "",
        contacts,
        agents,
        agentsWithGroup,
        statuses,
        priorities,
        ticketTypes,
      } = queryVal;
      const { payload, agentGroupObj }: any = generatePayload(queryVal);
      const { allGroupIds, withoutCloseTickets } = generateQuery(
        queryVal,
        allData
      );
      const apiPayload = {
        ...payload,
        statusIds: statuses.length === 0 ? withoutCloseTickets : statuses,
        groupIds:
          agentsWithGroup?.length > 0 ? agentGroupObj.groups : [...allGroupIds],
        agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
        search: searchVal,
        ticketTypeIds: ticketTypes,
        startDate: payload.startDate,
        endDate: payload.endDate,
        contactIds: [],
        priorityIds: priorities,
        page: pageNo,
      };

      dispatch({ type: "SHOW_LOADER", payload: true });
      const response: any = await axios.post(getTicketsByFilterUrl, apiPayload);
      const data = {
        pageNo: pageNo,
        hasMore: response?.data?.length === 100 ? true : false,
        data: response.data,
      };
      dispatch({ type: "SHOW_LOADER", payload: false });

      dispatch({ type: "SAVE_OTHER_TICKETS", payload: data });
      if (callback) callback("0");
    } catch (error: any) {
      console.log(error.response);
      dispatch({ type: "SHOW_LOADER", payload: false });
      if (callback) callback("1");
      return error.response;
    }
  };
};
export const loadNewTickets = (
  queryVal: any,
  allData: any,
  pageNo: any,
  existingData: any,
  AllInfo: any,
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const { searchVal = "" } = queryVal;
      const { payload }: any = generatePayload(queryVal);
      const { newTicketIds, allGroupIds } = generateQuery(queryVal, allData);
      const apiPayload = {
        ...payload,
        statusIds: newTicketIds,
        groupIds: [0, ...allGroupIds],
        search: searchVal,
        startDate: payload.startDate,
        endDate: payload.endDate,
        page: pageNo,
      };

      dispatch({ type: "SHOW_LOADER", payload: true });
      const response: any = await axios.post(getTicketsByFilterUrl, apiPayload);
      const data = {
        pageNo: pageNo,
        hasMore: response?.data?.length === 100 ? true : false,
        data: [...existingData, ...response.data],
      };
      dispatch({ type: "SHOW_LOADER", payload: false });
      // console.log('SAVE_NEW_TICKETS_res',response,data)
      dispatch({ type: "SAVE_NEW_TICKETS", payload: data });
      if (callback) callback("0");
    } catch (error: any) {
      if (callback) callback("1");
      dispatch({ type: "SHOW_LOADER", payload: false });
      console.log(error.response);
      return error.response;
    }
  };
};
export const loadNewToMeTickets = (
  queryVal: any,
  allData: any,
  pageNo: any,
  existingData: any,
  AllInfo: any,
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const { searchVal = "", agents, agentsWithGroup, ticketTypes } = queryVal;
      const { payload, agentGroupObj }: any = generatePayload(queryVal);
      const { newtoMeTicketIds, allGroupIds } = generateQuery(
        queryVal,
        allData
      );
      const apiPayload = {
        ...payload,
        statusIds: newtoMeTicketIds,
        search: searchVal,
        ticketTypeIds: ticketTypes,
        groupIds:
          agentsWithGroup?.length > 0 ? agentGroupObj.groups : [...allGroupIds],
        agentIds: agentsWithGroup?.length > 0 ? agentGroupObj.agents : agents,
        startDate: payload.startDate,
        endDate: payload.endDate,
        page: pageNo,
      };

      dispatch({ type: "SHOW_LOADER", payload: true });
      const response: any = await axios.post(getTicketsByFilterUrl, apiPayload);
      const data = {
        pageNo: pageNo,
        hasMore: response?.data?.length === 100 ? true : false,
        data: [...existingData, ...response.data],
      };
      dispatch({ type: "SHOW_LOADER", payload: false });
      dispatch({ type: "SAVE_NEW_TO_ME_TICKETS", payload: data });
      if (callback) callback("0");
    } catch (error: any) {
      if (callback) callback("1");
      dispatch({ type: "SHOW_LOADER", payload: false });
      console.log(error.response);
      //alert('facing technical error!')
      return error.response;
    }
  };
};

/* ======== SEARCH ON LINK ACTION ======== */
export const loadTicketsOnSearch = (searchVal: any,statuses:number[]=[],groups:number[]=[], callback?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch({ type: "SHOW_LINK_LOADER", payload: true });
      // const endDateVal = new Date();
      // const currentTime = new Date(`${endDateVal.getFullYear() - 1}`);
      let endDateTime:any = new Date();
      let startDateTime:any =  new Date(`${endDateTime.getFullYear() - 1}`);
      startDateTime = new Date (Date.UTC(startDateTime.getFullYear(), startDateTime.getMonth(), startDateTime.getDate(),0,0,0,0)).toISOString();
      endDateTime = new Date (Date.UTC(endDateTime.getFullYear(), endDateTime.getMonth(), endDateTime.getDate(),23,59,59,59)).toISOString();
      const payload = {
        startDate: startDateTime,
        endDate: endDateTime,
        search: "",
        contactIds: [],
        agentIds: [],
        groupIds: groups,
        ticketTypeIds: [],
        priorityIds: [],
        statusIds: statuses,
        sortPropString: "OpenDateTime",
        ticketFilterPropString: "OpenDateTime",
        sortTypeString: "Desc",
        page: 0,
        count: 30,
      };

      const query = { ...payload, search: searchVal };
      const response = await axios.post(getTicketsByFilterUrl, query);
      if (callback) callback(response.data);
      dispatch({ type: "SHOW_LINK_LOADER", payload: false });
    } catch (error: any) {
      console.log(error.response);
      dispatch({ type: "SHOW_LINK_LOADER", payload: false });
      return error.response;
    }
  };
};

/* ============ TASK ============ */
export const getSSPTaskAndTicketById = (taskId: string|number, ticketId:string|number, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const urls = [`${getUserTicketsUrl}/${ticketId}`,`${AddUserTaskUrl}/${taskId}`]
      Promise.all(
        urls.map((endpoint: string, index: number) => axios.get(`${endpoint}`))
      ).then((all) => {
        console.log("getAllDAshboardData>>", all);
        // dispatch(asyncCallback(all, callback));
        let allresponse = all.map((i)=>i.data)
        try {
          if (callback) callback("0", {
            ticket : all[0].data,
            task : all[1].data,
          });
        } catch (e) {
          console.log("get Ticket and Task By ID", e);
        }
      });
    } catch (e: any) {
      console.log("getTaskByID", e.response);
      if (callback) callback("1", e.response);
    }
  };
}
export const getTaskById = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios(`${AddUserTaskUrl}/${id}`);
      console.log("responseresponse", response);
      try {
        if (callback) callback("0", response.data);
      } catch (e) {
        console.log("getTaskByID", e);
      }
    } catch (e: any) {
      console.log("getTaskByID", e.response);
      if (callback) callback("1", e.response);
    }
  };
};

export const updateTicketTask = (
  taskObj: any,
  callback?: any,
  isSSP?: boolean
) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.put(
        `${isSSP ? AddUserTaskUrl : AddTicketTasktaskUrl}/${taskObj?.id}`,
        taskObj
      );
      try {
        if (callback) callback("0", response.data);
      } catch (e) {
        console.log("resolveAndProceedTask", e);
      }
    } catch (e: any) {
      console.log("resolveAndProceedTask", e.response);
      if (callback) callback("1", e.response);
    }
  };
};
export const sspResolveAndProceedTask = (taskObj: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response: any = await axios.put(
        `${AddUserTaskUrl}/${taskObj?.id}`,
        taskObj
      );
      try {
        if (callback) callback("0", response.data);
      } catch (e) {
        console.log("getTicketByID", e);
      }
    } catch (e: any) {
      console.log("getTicketByID", e.response);
      if (callback) callback("1", e.response);
    }
  };
};
export const proceedNxtTask = (
  nxtActionApi: any,
  taskObj: any,
  ssp: boolean
) => {
  return async (dispatch: any) => {
    try {
      //getTicketsUrl
      //console.log('workflowNodesList:any,->',nxtActionApi)
      if (!nxtActionApi?.name) return;

      if (nxtActionApi?.name === "email") {
        dispatch(sendEmail(ssp, taskObj, nxtActionApi));
        return;
      }

      const response = await axios.post(
        `${ssp ? getUserTicketsUrl : getTicketsUrl}/${nxtActionApi?.name}/${
          taskObj?.ticketId
        }?workflowNodeId=${nxtActionApi?.id}`,
        nxtActionApi
      );
      console.log("workflowNodesList:any,->", response);
    } catch (e: any) {
      console.log("getTicketByID", e.response);
    }
  };
};
const emailTemplatesUrl = `${configs.BASE_URL}/EmailTemplates`;
export const ContactGetUrl = `${configs.BASE_URL}/Contacts`;

export const sendEmail = (
  ssp: boolean = false,
  taskObj: any,
  nxtActionApi: any
) => {
  return async (dispatch: any) => {
    try {
      
      console.log(
        "ssp:boolean=false,ticketId:any,nxtActionApi",
        ssp,
        taskObj,
        taskObj?.ticketId,
        nxtActionApi
      );
      //nxtActionApi - take email template id from here
      const contactInfo = await axios(
        `${ContactGetUrl}/${taskObj?.ticketTaskLogs?.contactId}`
      );
      const getEmailTemplate = await axios(
        `${emailTemplatesUrl}/${nxtActionApi?.emailTemplateId}`
      );
      console.log("getEmailTemplate", getEmailTemplate, contactInfo);

      const payload: any = {
        ...nxtActionApi,
      };
      if (!payload.emailTemplates) return;
      payload.emailTemplates.to = `${contactInfo?.data?.email}`; //ticket contact's email ID
      // const payload = {
      //   "isActive": true,
      //   "source": "Phone",
      //   "ticketLogs": {
      //     "to": `${contactInfo?.data?.email}`,//ticket contact's email ID
      //     "from": `${getEmailTemplate?.data?.from}`,// from email template
      //     "title": `${getEmailTemplate?.data?.subject}`,// from email template
      //     "description": `${getEmailTemplate?.data?.body}`,// from email template
      //     "status": 6,// ?????
      //     "source": "Phone",
      //     "contactId": taskObj?.ticketTaskLogs?.contactId, //ticket contact's email ID
      //     "ownerId": taskObj?.ticketTaskLogs?.ownerId//ticket contact's email ID
      //   }
      // }
      const response = await axios.post(
        `${ssp ? getUserTicketsUrl : getTicketsUrl}/email/${
          taskObj?.ticketId
        }?workflowNodeId=${nxtActionApi?.id}`,
        payload
      ); //?workflowNodeId=${nxtActionApi?.id}
      console.log("responsesendEmail", response.data);
    } catch (e: any) {
      console.log("sendEmail", e.response);
    }
  };
};
//user/TicketTask/approval/details?ticketId=32&nodeId=22&statusId=121
export const getApprovalDetails = (
  ticketId: any,
  nodeId: any,
  statusId: any,
  callback?: any
) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(
        `${AddUserTaskUrl}/approval/details?ticketId=${ticketId}&nodeId=${nodeId}&statusId=${statusId}`
      );
      const nodeInfo = await axios(
        `${configs.BASE_URL_Ticket}/user/WorkflowNode/details/${nodeId}`
      );
      console.log(
        "getApprovalDetails:any,->",
        response?.data,
        "-0-0-0-",
        nodeInfo?.data
      );
      const temp = {
        ...response?.data,
        ...nodeInfo?.data,
      };
      try {
        if (callback) callback("0", temp);
      } catch (e) {
        console.log("getApprovalDetails", e);
      }
    } catch (e: any) {
      console.log("getApprovalDetails", e.response);
    }
  };
};

// =============== DASHBOARD SUMMARY ================
export const storeDashboardSummary = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${userGroupInfoUrl}`);
      dispatch({
        type: "STORE_GROUP_INFO",
        payload: response?.data,
      });
    } catch (e: any) {
      console.log("getApprovalDetails", e.response);
    }
  };
};

export const reportTicketLoad = (count:number=10000)=>{
  return async (dispatch: any) => {
    try {
      const apiPayload = {
        "startDate": "2022-12-31T19:00:00.000Z",
        "endDate": "2024-04-04T17:35:43.888Z",
        "search": "",
        "contactIds": [],
        "agentIds": [],
        "groupIds": [0,1,2,3,4,5,6,7,8,9,10,11,12,13],
        "ticketTypeIds": [],
        "priorityIds": [],
        "statusIds": [],
        "sortPropString": "OpenDateTime",
        "ticketFilterPropString": "OpenDateTime",
        "sortTypeString": "Desc",
        "page": 0,
        "count": count,
      }
      
      const pages = [0,1];
      dispatch({
        type: "TOGGLE_CSV_LOADER",
        payload: true,
      });
      Promise.all(
        pages.map((pageNumber: number) =>
          axios.post(`${configs.BASE_URL}/Reports/pivot/filters`, {
            ...apiPayload,
            ["page"]: pageNumber,
          })
        )
      ).then((all: any) => {
        const allPageResults: any[] = all.map((x: any) => x.data);
        dispatch(storeAllCSVResponses(allPageResults.flat()));
        console.log("allPageResults", allPageResults.flat());
      });
      Promise.all(pages.map((pageNumber:number) => axios.post(`${configs.BASE_URL}/Reports/csv`,{...apiPayload,["page"] :pageNumber}))).then(
        (all:any)=> {
          const allPageResults:any[] =  all.map((x:any)=>x.data)
          
          dispatch(storeAllCSVResponses(allPageResults.flat()))
          console.log("allPageResults",allPageResults.flat());
        }
      );
    } catch (error: any) {
      console.log(error.response);
      return error.response;
    }
  };
};

export const getBulkNewTicket = (search:string,statusIds:number[]=[],groupIds:number[]=[],count:number=1000,callback?:any)=>{
  return async (dispatch: any) => {
    try {
      let startDateTime = new Date("0001");
      let endDateTime = new Date();
      let toData = moment(endDateTime).toISOString();
      let fromData = moment(startDateTime).toISOString();
      const apiPayload = {
        "startDate": fromData,
        "endDate": toData,
        "search": search || "",
        "contactIds": [],
        "agentIds": [],
        "groupIds": [0,...groupIds],
        "ticketTypeIds": [],
        "priorityIds": [],
        "statusIds": statusIds,
        "sortPropString": "OpenDateTime",
        "ticketFilterPropString": "OpenDateTime",
        "sortTypeString": "Desc",
        "page": 0,
        "count": count,
      }
      
      
      dispatch({
        type: "TOGGLE_CSV_LOADER",
        payload: true,
      });
      const response = await axios.post(getTicketsByFilterUrl, apiPayload)
     
      dispatch(storeAllCSVResponses(response.data.map((i:ITicket)=>{
          return {
            ...i,
            ticketLogs :{
              ...i.ticketLogs,
              openDateTime : i.ticketLogs.openDateTime ? moment(i.ticketLogs.openDateTime).format('DD/MM/YYYY') : "-"
            }
          }
        })))
        if(callback)callback()
    } catch (error: any) {
      dispatch({
        type: "TOGGLE_CSV_LOADER",
        payload: false,
      });
      console.log(error.response);
      return error.response;
    }
  };
};

export const bulkUpdateNewTickets = (ticketIds:number[],apiPayload:any,callback:any)=>{
  return async (dispatch: any) => {
    try {      
      dispatch({type: "TOGGLE_CSV_LOADER",payload: true});
      const response = await axios.put(`${getTicketsUrl}/bulk?ids=${ticketIds.join()}`, apiPayload)
      if(apiPayload.ticketLogs.groupId){
        dispatch(triggerAppAlert(`Success! ${ticketIds.length} Ticket${ticketIds.length === 1 ? "" : "s"} updated`, 'success'))
      }else{
        dispatch(triggerAppAlert(`Success! ${ticketIds.length} Ticket${ticketIds.length === 1 ? "" : "s"} deleted`, 'success'))
      }
      if(callback) callback()
      // dispatch(getBulkNewTicket())
    } catch (error: any) {
      if(apiPayload.ticketLogs.groupId){
        dispatch(triggerAppAlert(`Tickets updation failed! Please try again later.`, 'warning'))
      }else{
        dispatch(triggerAppAlert(`Tickets deletion failed! Please try again later.`, 'warning'))
      }
      console.log(error.response);
      dispatch({type: "TOGGLE_CSV_LOADER",payload: false});
      return error.response;
    }
  };
};


export const createNewTicket = (payload:any={},callback?:any) => {
  return async (dispatch: any) => {
    try {
      const {emailTemplateId=""} = payload;
      const response: any = await axios.post(`${getTicketsUrl}`,payload);
      

      //============= BLOB CALL =========
      
      Promise.all(
        (response?.data?.ticketLogs?.ticketLogAttachmentsList || []).map((attachment:any, index: number) => {
          const formData = new FormData();
          formData.set("", payload.files[index]);
          return fetch(`${attachment.attachmentPath}`, {
            method: "PUT",
            headers: {
              "x-ms-blob-type": "BlockBlob",
              "Content-Type": payload.files[index]?.type,
            },
            body: payload.files[index],
          });
          // return axios.put(`${attachment.attachmentPath}`,payload.files[index],{headers: {
          //   "x-ms-blob-type": "BlockBlob",
          //   "Content-Type": payload.files[index]?.type,
          // }})
        })
      ).then((all) => {
        console.log("getAllDAshboardData>>", all);
      });
      if (callback) callback("0", response.data);
      dispatch(triggerAppAlert(`Success! Ticket created successfully.`, 'success'))

      if(emailTemplateId !==""){
        dispatch(toggleEmailPopup(
          {
            emailTemplateId,
            ticketId: response.data.id,
          }
        ))
      }
    } catch (error) {
      dispatch(triggerAppAlert("Ticket creation failed", "error"));
      return console.log("err", error);
    }
  };
};
export default storeAllTickets;
