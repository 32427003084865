import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import { showErrMsg } from "../TicketsManagement/TicketsManagement";
import { assetTypeUrl } from "./AssetTypes";
import { OrganisationUrl } from "../CMDB/Organisation";
import { contactAidUrl, triggerAppAlert } from "../SelfServicePortal/ContactAid/ContactAid";
import LanguageData from "../../../configs/LanguageData.json";
export const url = `${configs.BASE_URL}/Asset`;
export const assetGetUrl = `${configs.BASE_URL}/Asset`;
export const SspStatusUrl = `user/TicketStatus`;
const syncAssetsUrl = `${url}/azure/intune/sync`;
export const filesApiUrl = `${configs.BASE_URL_FILE_UPLOAD}/Files/`
export const GET_ASSETS = 'GET_ASSETS';
export const GET_ASSETS_SSP = 'GET_ASSETS_SSP';
export const ADD_ASSETS = 'ADD_ASSETS';
export const GET_ASSETS_TICKETS = 'GET_ASSETS_TICKETS';
export const storeAllAssets = (val: any) => ({
    type: GET_ASSETS,
    payload: val,
});
export const storeAllAssetSSP = (val: any) => ({
    type: GET_ASSETS_SSP,
    payload: val,
});
export const storeAllAssetsTicket = (val: any) => ({
    type: GET_ASSETS_TICKETS,
    payload: val,
});
export const addNewAsset = (val: any) => ({
  type: ADD_ASSETS,
  payload: val,
});


export const StoreAssetLazy = (data: any) => ({
  type: "LOAD_MORE_ASSETS",
  payload: data,
});
export const GetAssets = (data: any) => ({
  type: GET_ASSETS,
  payload: data,
});
export const getAssetsAction = (altUrl?:string|null,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(altUrl ? `${configs.BASE_URL}/${altUrl}`: url);
      console.log('get_asset_res',response);
      // response.data = response.data.map((i:any)=>{
      response.data = (response?.data?.assets || response?.data || []).map((i:any)=>{
        const {id,name,contacts,assetTag} = i ;
        return {...i,searchKey:`#${id}_${name}_${assetTag}_${contacts?.name}`.toLowerCase()}
      })
      if(altUrl){
        dispatch(storeAllAssetSSP(response.data));
      }else{
        dispatch(storeAllAssets(response.data));
      }
      try {
        if(callback) callback(response.data)
      } catch (error) {
        
      }
    } catch (error:any) {
      console.log('get_asset_res',error.response);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const GetAssetLazy = (pageNum: Number,isActive:boolean,search:string,callback:any,pageSize:number) => {
  return async (dispatch: any) => {
    try {
      let searchVal = search ? `&search=${search}` : "";
      let response = await axios.get(`${url}?page=${pageNum}&count=${pageSize}&isActive=${isActive}${searchVal}`);
      console.log("getContactsLazy-e",new Date())
      if(callback) callback(pageNum,response.data, response.data.assets.length === pageSize,response.data.totalCount);
      if(pageNum === 0){
        dispatch(storeAllAssets(response.data.assets));
      } else {
        dispatch(StoreAssetLazy(response.data.assets));
      }
    } catch (error:any) {
      console.log(error);
    }
  };
}
export const statusChange = (id:number,status:boolean) => {
  const statusMsg = status ? `${LanguageData.SUCCESS_ASSET}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_ASSET}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/status/change?ids=${id}&status=${status}`);
      console.log('status',response);
      // dispatch(getAssetsAction());
      dispatch(triggerAppAlert(statusMsg, 'success'))
    } catch (error:any) {
      console.log('get_asset_res',error.response);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getMultipleAssetsByIds = (ids:string, callback:any) => {
  return async (dispatch: any) => {
    try{
      const allIds = ids.split(',');
      Promise.all(allIds.map((id:string,index:number) => axios.get(`${url}/${id}`))).then(
        (all)=> {
          console.log('allresponse',all)
          if(callback) callback("0",all.map((i:any)=>i.data));
          
        }
      ); 
      //customAPiCall(rolesAccess,currAgent,callback); 
    }catch(e){
      console.log('get all data', e)
      if(callback) callback("1",e);
    }
  }
}
export const getAssetByIdAction = (id:string, callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${url}/${id}`);
      try{
        callback('0',response.data)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
    } catch (error:any) {
      try{
        callback('1',error.response)
      }catch(e){
        console.log('get asset by ID err','0',e)
      }
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const getAttachmentsByIdAction = (id:number | string, callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${filesApiUrl}${id}`);
      // response.data.filePath = response.data.filePath.replaceAll("https://simplisysstoragev1.blob.core.windows.net","");
      // response.data.filePath = "https://simplisysstoragev1.blob.core.windows.net" + response.data.filePath;
      callback('0',response.data)
    } catch (error:any) {
      callback('1',error.response)
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const putAssetsAction = (data: any, callback:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${data.id}`,data);
      console.log('add_asset_res',response);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_ASSET}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });
      try{
        callback(true,response)
      }catch(e){
        console.log(e)
      }
    } catch (error:any) {
      try{
        callback(false,error.response)
      }catch(e){
        console.log(e)
      }
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      return console.log(error.response);
    }
  };
};
export const addAssetsAction = (data: any,type:string,callBack?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url,data);
      console.log('add_asset_res',response);
      //dispatch(storeAllAssets(data));
      callBack(true,response)
      dispatch(addNewAsset('0'));
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: type === 'add' ?  `${LanguageData.SUCCESS_NEW_ASSET}${LanguageData.TICKET_CREATED}` : type === 'tckt'? "Ticket Created Successfully" : type === "resolve" ? "Ticked Resolved Successfully!" :"Asset Deleted successfully!" ,
          severity: type === 'add' || type === 'tckt' || type === "resolve" ?  "success" : "error" ,
          open: true,
        },
      });
      ;
    } catch (error:any) {
      // callBack('1',error)
      dispatch(addNewAsset('1'));
      console.log('add_asset_res',error);
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
          severity: "error",
          open: true,
        },
      });
      callBack(false,false)
      return console.log(error.response);
    }
  };
};

export const getStatitics = async (id:any,effectiveDate: any,contactUrl?:string) => {
  try {
      let result = await axios.get(contactUrl
        ? `${configs.BASE_URL}${contactUrl}/Ticket/statistics?assetId=${id}&effectiveDate=${effectiveDate}`
        : `${configs.BASE_URL}/Ticket/statistics?assetId=${id}&effectiveDate=${effectiveDate}`);
      return result.data;
  } catch (e) {
      console.log(e)
      return false
  }
}

export const addAssetsTicketsAction = (data: any) => {
    return async (dispatch: any) => {
      try {
       // const response = await axios.post(url,data);
       dispatch(storeAllAssetsTicket(data));
        //dispatch(addNewAsset('0'));
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: "New ticket created successfully!",
            severity: "success",
            open: true,
          },
        });
        ;
      } catch (error:any) {
        //dispatch(addNewAsset('1'));
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error.response ? typeof error.response.data === 'string' ? error.response.data : "Please Enter valid Details to Proceed!":"Something went wrong! Please try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
  export const SyncAssetAction = () => {
    return async (dispatch: any) => {
      try {
        const response:any = await axios(syncAssetsUrl);
        ;
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: "Active Directory Synced Successfully",
            severity: "success",
            open: true,
          },
        });
        dispatch(getAssetsAction());
      } catch (error:any) {
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === 'string'?error.response.data : "Something went wrong! Try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
  };
  export const postWhatsAppOnTicket = (data: any, id: any,callback:any) => {
    return function (dispatch: any) {
      axios.put(`${configs.BASE_URL}/Ticket/send/whatsapp/message`, data).then((resp) => {
        try {
          dispatch({
            type: "postWhatsAppOnTicket",
          });
          dispatch({
            type: ActionType.SNACKBAR,
            payload: {
              message: `${("Message Sent")}`,
              severity: "success",
              open: true,
            },
          })
          // dispatch(getTicket());
          callback()
        } catch (error:any) {
          if (error.response) {
            dispatch({
              type: ActionType.SNACKBAR,
              payload: {
                message: error.response.data,
                severity: "error",
                open: true,
              },
            });
          }  
        }
      })
    }  
  };
  export const getMultiAssets = (ids:any[]=[],callback?:any) => {
    return async (dispatch: any) => {
      try{
        Promise.all(ids.map((id:string|number,index:number) => axios.get(`${url}/${id}`))).then(
          (all:any)=> {
            console.log('multiApiCalls',all)
            if(callback) {
              callback("0", all.map((i:any)=>i.data));
            }
          }
        ); 
        //customAPiCall(rolesAccess,currAgent,callback); 
      }catch(e){
        console.log('get all data', e)
        if(callback) callback("1",e);
      }
    }
  };
export const getAssetDataForWorkflow = (altUrl?:string) => {
    return async (dispatch: any) => {
      try {
        //const response = await axios(altUrl ? `${configs.BASE_URL}/${altUrl}`: url);
        const queries = [url,assetTypeUrl, contactAidUrl]
        Promise.all(queries.map((api:string,index:number) => axios.get(api))).then(
          (all)=> {
            const allResponses:any = all.map((i:any)=>i.data);
            const tempObj:any = {
              assets : [],
              assetsTypes : [],
              //OrgList : [],
              sspServiceList : [],
            }
            allResponses?.[0] && allResponses[0].map((i:any)=>{
              if(i.isActive){
                tempObj.assets.push({...i,"value":i.name,"label":i.name});
              }
            });
            allResponses?.[1] && allResponses[1].map((i:any)=>{
              if(i.isActive){
                tempObj.assetsTypes.push({...i,"value":i.name,"label":i.name});
              }
            });
            allResponses?.[2] && allResponses[2].map((i:any)=>{
              if(i.isActive){
                tempObj.sspServiceList.push({...i,"value":i.name,"label":i.name});
              };
            });
            
            dispatch({
              type: "WORKFLOW_FILTER_OPTIONS",
              payload:tempObj
            });
          }).catch((e)=>{
            console.log("ticketsRes12345",e)
            dispatch(showErrMsg("we're facing technical difficulties! Please contact helpdesk.", "warning"))
          })
        
      } catch (error:any) {
        console.log('get_asset_res',error.response);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: error?.response?.data && typeof error.response.data === 'string' ? error.response.data :"Something went wrong! Please try again.",
            severity: "error",
            open: true,
          },
        });
        return console.log(error.response);
      }
    };
};
export default addAssetsAction;
