import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Legend,
  Tooltip
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getRandomColor } from "./VerticalChart";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend, Tooltip);

const footer = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Total: ' + sum;
};


const HorizontalChartActual = ({
  id='myReportBar',
  label,
  dataSets,
  callback,
  isDarkMode,
  showLabels = true
}: {
  label: any;
  dataSets: any;
  callback?: any;
  id?: any
  isDarkMode?: any
  showLabels?: boolean
}) => {
  const data = {
    labels: label,
    datasets: dataSets,
  };
  const onchange = () =>{
    console.log(callback)
    if (callback) callback()
  }
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          generateLabels: (chart: any) => {
            const datasets = chart.data.datasets;
            const labels = chart.data.labels;

            return labels.map((label: any, index: any) => {
              const dataset = datasets.find((d: any) => d.data[index] !== undefined);
              const backgroundColor = dataset.data.map(() => getRandomColor())
              const dataValue = dataset?.data[index] || 0;
              return {
                text: `${label} (${dataValue})`,
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          usePointStyle: false, // Use regular legend markers (not point-style)
          color: !isDarkMode ? "black" : "white", // Dynamic color based on mode
          font: {
            size: 14, // Adjust font size based on `showLabels`
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10,      // Custom size for the legend box width (color box)
          boxHeight: 10,     // Custom size for the legend box height
          // generateLabels: (chart:any) => {
          //   const datasets = chart.data.datasets;
          //   const labels = chart.data.labels;
          //   return labels.map((label: any,index: any) => {
          //     const dataset = datasets.find((d: any) => d.data[index] !== undefined);
          //     return {
          //       text: label, // Label text
          //       fillStyle: dataset ? dataset.backgroundColor : "transparent", // Match bar color
          //       hidden: false,
          //       lineWidth: 0,
          //     };
          //   });
          // },
        }
      },
  
      tooltip: {
        usePointStyle: true,
        callbacks: {
          footer: footer,
        },
        filter: function (tooltipItem: any) {
          var value = tooltipItem.dataset.data[tooltipItem.dataIndex];
          return true;
        }
      },
      title: {
        display: false,
        text: "Chart.js B",
      },
      datalabels: {
        display: false,
        align: "center",
        anchor: "center",
        formatter: function (value: any) {
          value = value.toString();
          value = value.split(/(?=(?:...)*$)/);
          value = value.join(",");
          return value;
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hide x-axis labels
        },
        grid: {
          drawTicks: false, // Optionally hide tick marks on the x-axis
        },
      },
      y: {
        ticks: {
          callback: (yValue: any) => Math.floor(yValue), // format to your liking
        color: !isDarkMode ? "black":"white",
          font: {
            size: 14, // Adjust font size for y-axis ticks
          },
        },
      },
    },
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    indexAxis: 'y', // Set this to 'y' to make the bars horizontal
  };
  
  React.useEffect(()=>{
    setTimeout(()=>{
      onchange()
    },100)
  },[label])
  return <Bar height={400} id={id} data={data} options={options} onChange={onchange}
  />;
};
export default HorizontalChartActual;
