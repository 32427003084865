import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from "chartjs-plugin-datalabels";
import { getRandomColor } from '../horizontal-chart/VerticalChart';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({
  labels,
  datasets,
  showLegend = true,
  showLabels = true,
  isDarkMode = false,
}: {
  labels: any;
  datasets: any;
  showLegend?: boolean;
  showLabels?: boolean;
  isDarkMode?: boolean;
}) => {
  const options: any = {
    maintainAspectRatio: false, // Don't maintain w/h ratio
    plugins: {
      legend: {
        display: showLegend,
        labels: {
          generateLabels: (chart: any) => {
            const { datasets, labels } = chart.data;
            return labels.map((label: any, index: any) => {
              const dataValue = datasets[0]?.data[index] || 0; // Get data value
              const backgroundColor = getRandomColor(); // Get color
              return {
                text: `${label} (${dataValue})`, // Include data count in legend label
                fillStyle: backgroundColor,
                hidden: false,
                lineWidth: 0,
              };
            });
          },
          color: !isDarkMode ? "black" : "white",
          usePointStyle: false, // Use regular legend markers (not point-style)
          font: {
            size: 14, // Adjust font size
          },
          padding: showLabels ? 8 : 3,
          boxWidth: 10, // Custom size for the legend box width (color box)
          boxHeight: 10, // Custom size for the legend box height
        },
      },
      datalabels: {
        display: true,
        labels: {
          value: {
            color: !isDarkMode ? "black":"white",
            font: { size:14 }, // Conditional font size for value labels
            formatter: function (value: any, ctx: any) {
              return ctx.active
                ? ""
                : ctx.chart.data.datasets[0].data[ctx.dataIndex] <= 0
                ? ""
                : ctx.chart.data.datasets[0].data[ctx.dataIndex];
            },
          },
        },
      },
    },
  };

  const data = {
    labels: labels,
    datasets: datasets,
  };

  return <Pie data={data} options={options} />;
};

export default PieChart;
