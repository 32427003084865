import axios from "axios";
import configs from "../../../configs/config";
import { ActionType, snackbar } from "../Snackbars";
import intl from "react-intl-universal";
import { resolve } from "dns";
// import { formCategoryData } from "../../../components/userManagement/Role/formAgentData";
import { Language } from "@mui/icons-material";
import LanguageData from "../../../configs/LanguageData.json"

export const url = `${configs.BASE_URL}/Category`;
export const urlV2 = `${configs.BASE_URL}/Category/v2`;
export const getAllCategoryIdsByTypeIdUrl = `${configs.BASE_URL}/category/ids/by/tickettypeid?tickettypeid=`;
export const getCategoryByTicketTypeId = `${configs.BASE_URL}/Category/by/tickettypeid?tickettypeid=`;
const deleteUrl = `${configs.BASE_URL}/Category/permanent/delete/`;
const changeStatus = `${configs.BASE_URL}/Category/status/change?ids=`;
const bulkDelete = `${configs.BASE_URL}/Category/permanent/delete?ids=`;
const unassignedTicketurl = `${configs.BASE_URL}/Category/unassigned/tickettypes`;
const catgoryById =`${configs.BASE_URL}/user/Category`

export const storeCategoryList = (val: any) => ({
  type: "getCategory",
  payload: val,
});
export const storeCategoryListForRoles = (val: any) => ({
  type: "STORE_CATEGORY_FOR_ROLES",
  payload: val,
});
export const storeCategoryTree = (val: any) => ({
  type: "storeCategoryTree",
  payload: val,
});
const addCategory = (data: any,callback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch({
        type: "addCategory",
        payload: response,
      });
      // dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_NEW_CATEGORY}${LanguageData.TICKET_CREATED}`,
          severity: "success",
          open: true,
        },
      });
      callback();
    } catch (error: any) {
      if (error.response) {
        let errorMessage = error.response?.data
      
        const errors = error.response.data.errors;
      
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          if (firstErrorKey && errors[firstErrorKey].length > 0) {
            errorMessage = errors[firstErrorKey][0];
            if (errorMessage === "The Name field is required." && firstErrorKey.includes("SubCategoryList")) {
              errorMessage = "Subcategory name cannot be empty";
            }
          }
        }
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: errorMessage,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
};

export const storeCategoryObject = (val: any) => ({
  type: "getCategoryObject",
  payload: val,
});

const formCategory = (arr: any) => {

  const obj: any = {};
  const objById: any = {};
  arr.map((i: any) => {
      obj[i?.id] = i?.name;
      objById[i?.id] = {
          ...i,
          "adminChildIdLeavel1": i.id,
          "adminChildIdLeavel2": 0,
          "adminChildIdLeavel3": 0,
          "adminChildIdLeavel4": 0,
        };
      i.subCategoryList.map((j: any) => {
          obj[j?.id] = j?.name;
          objById[j?.id] = {
              ...j,
              "adminChildIdLeavel1": i.id,
              "adminChildIdLeavel2": j.id,
              "adminChildIdLeavel3": 0,
              "adminChildIdLeavel4": 0,
            };
          j.itemList.map((k: any) => {
              obj[k?.id] = k?.name;
              objById[k?.id] = {
                  ...k,
                  "adminChildIdLeavel1": i.id,
                  "adminChildIdLeavel2": j.id,
                  "adminChildIdLeavel3": k.id,
                  "adminChildIdLeavel4": 0,
                };
              k.childrenItemList.map((l: any) => {
                obj[l?.id] = l?.name;
                objById[l?.id] = {
                    ...l,
                    "adminChildIdLeavel1": i.id,
                    "adminChildIdLeavel2": j.id,
                    "adminChildIdLeavel3": k.id,
                    "adminChildIdLeavel4": l.id,
                  };
              });
          });
      });
  });

  return {obj,objById};
};
export const getAllCategoryIdsByTypeId = (ticketTypeId:number,callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getAllCategoryIdsByTypeIdUrl}${ticketTypeId}`);
      dispatch({
          type : "REMOVE_UNUSED_TYPES",
          payload : {
              ticketTypeId,
              data : response.data
          }
      })
      if(callback) callback({isSuccess : true, data : response.data,ticketTypeId})
    }catch(e){
      if(callback) callback({isSuccess : false, data : e})
      return console.error(e);
    }
  }
}

export const getCategoryByTypeId = (ticketTypeId:number,callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${getCategoryByTicketTypeId}${ticketTypeId}`);
      const tempData = {...response.data}
      sortSubCategories(tempData)
      if(callback) callback({isSuccess : true, data : tempData})
    }catch(e){
      if(callback) callback({isSuccess : false, data : e})
      return console.error(e);
    }
  }
}
export const getCategoryV2 = (callback?: any,param:string="?isActive=true") => {
  return async (dispatch: any) => {
    try {
      const response = await axios(`${urlV2}${param}`);
      response.data = response.data.map((i:any)=>{
        i.ticketTypeId =  i?.categoryTicketTypeList?.[0]?.ticketTypeId || 0
        return i;
      })
      dispatch(storeCategoryList(response));
      dispatch(storeCategoryListForRoles(response.data));
      dispatch(loading(false));
      // const activeList = response.data.filter((i:{isActive:boolean})=>i.isActive)
      if(callback) callback({isSuccess : true, data : response.data})
    }catch(e){
      if(callback) callback({isSuccess : false, data : e})
      return console.error(e);
    }
  }
}

export const getCategory = (callback?: any, altUrl?: string) => {
  return async (dispatch: any) => {
    try {
      const response = await axios(
        altUrl ? `${configs.BASE_URL}/${altUrl}` : urlV2
      );
      response.data = response.data.map((i:any)=>{
        i.ticketTypeId =  i?.categoryTicketTypeList?.[0]?.ticketTypeId || 0
        return i;
      })
      dispatch(loading(false));
      dispatch(storeCategoryList(response));
      // dispatch(storeCategoryObject(formCategory(response.data)));
      const treeFormatted:any = [];
      response.data.map((i:any)=>{
        if(!i?.isActive) return;
        const {id,name,isActive} = i 
        treeFormatted.push({
            id,name,
            isActive,
            ticketTypeId : i?.categoryTicketTypeList?.[0]?.ticketType ? i?.categoryTicketTypeList?.[0]?.ticketType?.id : 0,
            categoryList : null //formCategoryData(i)
        });
      })
      console.log('====================================');
      console.log("dispatch(storeCategoryTree(treeFormatted));",treeFormatted);
      console.log('====================================');
      dispatch(storeCategoryTree(treeFormatted));
      if (callback) callback("0", response.data);
    } catch (error: any) {
      if (callback) callback("1", error.response);
      return console.log(error);
    }
  };
};

export const unassignedTicket = () => {
  return async (dispatch: any) => {
    try {
      const response = await axios(unassignedTicketurl);
      dispatch(loading(false));
      dispatch({
        type: "unassignedTicket",
        payload: response,
      });
    } catch (error) {
      return console.log(error);
    }
  };
};
export const getCategoryByIdForRoles = (id: any, callback?: any,valToPassCallback?:any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      const ticketTypeId =  response?.data?.categoryTicketTypeList?.[0]?.ticketTypeId || 0;
      response.data.ticketTypeId = ticketTypeId;
      response.data = JSON.parse(JSON.stringify(response.data).replaceAll('subCategoryList',"children").replaceAll('itemList',"children").replaceAll('childrenItemList',"children").replaceAll('name',"title"));
      console.log("getCategoryByIdForRoles",response.data)
      if (callback) callback("0", response.data,valToPassCallback);
    } catch (error: any) {
      if (callback) callback("1", error.response,valToPassCallback);
      return console.log(error);
    }
  };
};
export const singleCategory = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${url}/${id}`);
      const tempData = {...response.data}
      sortSubCategories(tempData)
      dispatch({
        type: "singleCategory",
        payload: {data : tempData},
      });
      if (callback) callback("0", {data : tempData});
    } catch (error: any) {
      if (callback) callback("1", error.response);
      return console.log(error);
    }
  };
};
export const sspSingleCategory = (id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.get(`${catgoryById}/${id}`);
      response.data.sort((a:any, b:any) => a.name.localeCompare(b.name));
      if (callback) callback("0", response.data);
    } catch (error: any) {
      if (error.response) {
        let errorMessage = error.response?.data;
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: errorMessage,
            severity: "error",
            open: true,
          },
        });
      }

      if (callback) callback("1", error.response);
    }
  };
};


export const updateCategory = (data: any, id: any, callback?: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.put(`${url}/${id}`, data);

      dispatch({
        type: "updateCategory",
        payload: response,
      });

      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}`,
          severity: "success",
          open: true,
        },
      });

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        let errorMessage = error.response?.data
        const errors = error.response.data.errors;
        if (errors) {
          const firstErrorKey = Object.keys(errors)[0];
          if (firstErrorKey && errors[firstErrorKey].length > 0) {
            errorMessage = errors[firstErrorKey][0];
            if (errorMessage === "The Name field is required." && firstErrorKey.includes("SubCategoryList")) {
              errorMessage = "Subcategory name cannot be empty";
            }
          }
        }
        console.log("Error message:", errorMessage);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: errorMessage,
            severity: "error",
            open: true,
          },
        });
      }
    }
  };
};


export const bulkDeleteItem = (ids: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${bulkDelete}${ids}`);
      dispatch({
        type: "bulkDeleteItem",
        payload: response,
      });
      dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Category Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

// export const bulkDeleteItem = (ids: any) => {
//   return function (dispatch: any) {
//     axios.put(`${bulkDelete}${ids}`).then((resp) => {
//       dispatch({
//         type: "bulkDeleteItem",
//       });
//       dispatch(getCategory());
//       dispatch({
//         type: ActionType.SNACKBAR,
//         payload: {
//           message: "all Category deleted Successfully",
//           severity: "success",
//           open: true,
//         },
//       });
//     });
//   };
// };

export const multipalUpdateCategory = (data: any, ids: any, status: any) => {
  const idMsg =
    ids.length === undefined ? `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;
  const statusMsg = status ? `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.ACTIVE}` : `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED}${LanguageData.IN_ACTIVE}`;


  return function (dispatch: any) {
    axios
      .delete(`${changeStatus}${ids}&status=${status}`, data)
      .then((resp) => {
        dispatch({
          type: "multipalUpdateCategory",
        });
        dispatch(getCategory());
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: ids.length === undefined ? idMsg : statusMsg,
            severity: "success",
            open: true,
          },
        });
      });
  };
};

export const softDeleteCategory = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${url}/${id}`);
      dispatch({
        type: "softDeleteCategory",
        payload: response,
      });
      dispatch(getCategory());
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: `${LanguageData.SUCCESS_CATEGEORY}${LanguageData.TICKET_UPDATED} ${LanguageData.IN_ACTIVE}`,
          severity: "success",
          open: true,
        },
      });
    } catch (error) {
      return console.log(error);
    }
  };
};

export const deleteCategory = (id: any) => {
  return async (dispatch: any) => {
    try {
      const response = await axios.delete(`${deleteUrl}${id}`);
      dispatch({
        type: "deleteCategory",
        payload: response,
      });
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: "Category Deleted Successfully",
          severity: "success",
          open: true,
        },
      });
      dispatch(getCategory());
    } catch (error) {
      return console.log(error);
    }
  };
};

export const loading = (status: any) => {
  return {
    type: "loading",
    payload: status,
  };
};










interface Item {
  id: number;
  parentId: number;
  name: string;
  isActive: boolean;
  childrenItemList: Item[];
}

interface SubCategory {
  id: number;
  parentId: number;
  name: string;
  isActive: boolean;
  itemList: Item[];
}

interface Category {
  id: number;
  parentId: number;
  categoryTicketTypeList: null;
  name: string;
  isActive: boolean;
  subCategoryList: SubCategory[];
}
function sortSubCategories(data: Category): void {
  // Sort subCategoryList alphabetically by name
  if (data.subCategoryList && Array.isArray(data.subCategoryList)) {
    data.subCategoryList.sort((a, b) => a.name.localeCompare(b.name));

    // Sort each item's itemList by name
    data.subCategoryList.forEach((subCategory) => {
      if (subCategory.itemList && Array.isArray(subCategory.itemList)) {
        subCategory.itemList.sort((a, b) => a.name.localeCompare(b.name));

        // Recursively sort childrenItemList
        subCategory.itemList.forEach((item) => {
          if (item.childrenItemList && Array.isArray(item.childrenItemList)) {
            item.childrenItemList.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      }
    });
  }
}
export default addCategory;
