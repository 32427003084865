import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import InputBox from "../../common2/InputBox";
import CommonStyles from "../../common/CommonStyles";
import React, { useEffect, useState } from "react";
import BasicButton from "../../common/button/BasicButton";
import {
  ValidationResultS,
  iFormData,
} from "../../../pages/new-ticket/LatestTicket/Type";
import { isValidate } from "../../../pages/new-ticket/LatestTicket/Validation";
import { schemaForOrgCreationFormData } from "./Schema";
import { generateErrorList } from "../../../pages/new-ticket/LatestTicket/Form";
import logoImg from "./../../../assest/logoWIthS.png";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../redux/reducers/rootReducers";
import FreeTrialAction, {
  checkSubdomainAvailability,
  GetCoreProductsAction,
} from "../../../redux/actions/FreeTrial/FreeTrial";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../common/textField/CustomInput";
import CustomFormLabel from "../../common/form-label/CustomFormLabel ";
import PricingBanner from "./PricingBanner";
import { ActionType } from "../../../redux/actions/dashboard";
import PhoneInput from "react-phone-input-2";
import { callbackify } from "util";
import SaveIcon from "@mui/icons-material/Save";
import CustomSelect from "../../common/customSelect/CustomSelect";
import { getEditions } from "../../../redux/actions/userManagement/feature";

const MemoizedComponent: React.FC<any> = ({
  orgFormData,
  seconParam,
  children,
}) => {
  return React.useMemo(() => {
    return children;
  }, [orgFormData, seconParam]);
};
const checkSubdomainAvailabilityFunction = async (subDomain: string) => {
  let res = await checkSubdomainAvailability(subDomain);
  console.log('checkSubdomainAvailabilityFunction', res)
  return res;
};

function removeSubdomain(hostname: string) {
  const parts = hostname.split(".");
  // Always take the last two parts for domain and TLD
  return parts.slice(-2).join(".");
}

const DomainChecker = ({ callback }: { callback: any }) => {
  const [type, setType] = React.useState<any>("");
  const [message, setMessage] = React.useState<any>("");
  const [subDomain, setSubDomain] = React.useState<any>("");

  const generateColor = () => {
    if (subDomain?.length >= 3) {
      if (type == "success") {
        return "#00d200";
      } else if (type == "info") {
        return "#1aa7ff";
      } else {
        return "red";
      }
    } else {
      return "#1aa7ff";
    }
  };

 

  const handleSubDomainInput = (value: string) => {
    const sanitizedValue = value.toLowerCase().replace(/[^a-z0-9]/g, "");
    setSubDomain(sanitizedValue);
    if(sanitizedValue.length>=3){
    checkSubdomainAvailabilityFunction(`${sanitizedValue}.${removeSubdomain(window.location.hostname)}`)
      .then((res: any) => {
        let message = "";
        let type = "";

        if (res) {
          if (res?.status === 200) {
            message = `The domain ${sanitizedValue}.${removeSubdomain(
              window.location.hostname
            )} is available.`;
            type = "success";
            callback(sanitizedValue);
          } else {
            callback(sanitizedValue);
            message = `The domain ${sanitizedValue}.${removeSubdomain(
              window.location.hostname
            )} is not available. Please try another one.`;
            type = "error";
          }
        } else {
          message =
            `Somthing happened! Please try again@`;
          type = "error";
        }

        // Set the type and message for the user feedback
        setType(type);
        setMessage(message);
      })
      .catch((error) => {
        // Handle any potential errors from the checkSubdomainAvailabilityFunction
        setType("error");
        setMessage("An unexpected error occurred. Please try again later.");

        console.error("Error checking subdomain availability:", error);
      });
    }
    else{
      callback(sanitizedValue);
    }
  };

  return (
    <div
      style={{
        width: "550px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomFormLabel labelName={`Enter Subdomain`} isMandotary={true} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
        }}
      >
        <CustomInput
          isDisabled={false}
          readOnly={false}
          onClick={null}
          iconPosition={"start"}
          icon={false}
          isError={false}
          name="subDomain"
          label={"Enter the country"}
          onChange={(e: any) => {
            handleSubDomainInput(e?.target?.value);
          }}
          value={subDomain}
          helperText={""}
          placeholder={"Please Enter"}
        />{" "}
        <span
          style={{
            height: "6px",
            width: "7px",
            backgroundColor: "#464646",
            borderRadius: "50%",
            display: "inline-block",
          }}
        ></span>
        <span
          style={{
            fontStyle: "italic",
            fontSize: "18px",
            color: "#464646",
          }}
        >
          {removeSubdomain(window.location.hostname)}
        </span>
      </div>
      <span
        style={{
          color: generateColor(),
          marginTop: "5px",
          fontSize: "14px",
        }}
      >
        {subDomain?.length >= 3
          ? message
          : "Subdomain must be at least 3 characters long."}
      </span>
    </div>
  );
};

export default function FreeTrail1() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const coreProds = useSelector(
    (state: IState) => state?.FreeTrialReducer?.getProdList
  );
  const rawPlanList = useSelector(
    (state: IState) => state?.FeatureReducer?.viewOnlyFeatures
  );
  
  const planList = rawPlanList?.filter((plan:any) => plan.label !== "Enterprise")
  const [allCoreProducts, setallCoreProducts] = useState([]);
  const [planVal, setPlanVal] = useState<any>(null);
  const classes = CommonStyles();
  const [loading, setLoading] = useState(false);

  const [error, setError] = React.useState<ValidationResultS>({
    isValid: false,
    errors: {},
  });

  const [domainValue, setDomainValue] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && param?.id && planList?.length > 0) {
      const initialPlan = planList.find(
        (plan:any) => String(plan.id).trim() === String(param?.id).trim()
      );
      setPlanVal(initialPlan || null);
      setIsInitialized(true); 
    }
  }, [param?.id, planList, isInitialized]);
  useEffect(() => {
    if (planList?.length === 0) {
      const timeout = setTimeout(() => {
        dispatch(getEditions());
      }, 400)
      return () => clearTimeout(timeout);
    }
  }, [planList]);
  useEffect(() => {
    setallCoreProducts(coreProds);
  }, [coreProds]);

  useEffect(() => {
    dispatch(GetCoreProductsAction());
  }, [param]);
  const [orgFormData, setOrgFormData] = React.useState({
    firstName: "",
    lastName: "",
    persEmail: "",
    mobilePhone: "",
    userName: "",
    name: "",
    profEmail: "",
    profMobile: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postcode: "",
    country: "",
    coreProductId: 0,
  });

  const handleSelectChange = (value: unknown, type: string) => {
    setOrgFormData((prevUserFormData) => {
      return {
        ...prevUserFormData,
        [type]: value,
      };
    });
  };

  const callback = (status: any) => {
    console.log("callback", status);
    setLoading(false);
    if (status?.status == 201) {
      navigate("/success/1");
    } else {
      dispatch({
        type: ActionType.SNACKBAR,
        payload: {
          message: status?.data
            ? status?.data
            : "Something happened! Please check again!",
          severity: "error",
          open: true,
        },
      });
    }
  };

  const submitHandler = (event: any) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);

    const data: iFormData = {};

    formData.forEach((value, key) => {
      data[key] = value.toString();
    });
    console.log(data);

    const payload = {
      name: data.name,
      email: data.persEmail,
      mobile: data.mobilePhone,
      country: data.country,
      editionId: planVal?.id,
      isYearly: true,
      isMonthly: false,
      Subdomain: `${domainValue}.${removeSubdomain(window.location.hostname)}`,
      totalNumberOfAgents: 2,
      primaryAgent: {
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.profMobile,
        email: data.profEmail,
        userName: data.userName,
      },
      organisationCoreProduct: {
        coreProductId: Number(orgFormData.coreProductId),
      },
      organisationUrl: {
        url: "",
      },
    };

    const validationData = {
      firstName: data.firstName,
      lastName: data.lastName,
      persEmail: data.persEmail,
      //   mobilePhone: data.mobilePhone,
      userName: data.userName,
      name: data.name,
      profEmail: data.profEmail,
      profMobile: data.profMobile,
      addressLine1: data.addressLine1,
      //   addressLine2: data.addressLine2,
      city: data.city,
      postcode: data.postcode,
      country: data.country,
      coreProductId: Number(orgFormData.coreProductId),
      planTypeId: Number(planVal?.id),
    };

    const validation = isValidate(validationData, schemaForOrgCreationFormData);

    if (validation.isValid) {
      if(domainValue?.length>=3){
        setError({ isValid: false, errors: {} });
        console.log(payload);
        dispatch(FreeTrialAction(payload, callback));
      }
      else{
        setLoading(false);
        dispatch({
          type: ActionType.SNACKBAR,
          payload: {
            message: "Subdomain must be at least 3 characters long.",
            severity: "error",
            open: true,
          },
        });
      }
      
    } else {
      setLoading(false);
      setError(validation);
    }
  };
  const backHandler = () => {
    navigate("/pricing");
  };
  const handleChangePlan = (e: any) => {
    setPlanVal(e);
  };
  return (
    <>
      <form onSubmit={submitHandler}>
        <Box sx={{ width: "100%" }} className={`${classes.freeTrial} bg-theme`}>
          <Box>
            <PricingBanner />
            <Container maxWidth="lg">
              <div className="pt-3 sm-0 pb-3">
                <>
                  <Box>
                    <Box className="mb2">
                      <Grid container className="" spacing={1}>
                        <Grid item md={4} xs={12}>
                          <Typography variant="h6" className="">
                            Personal Information
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid container className="" spacing={1}>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"First Name"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.firstName}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the firstName"}
                            name="firstName"
                            // defaultValue={orgFormData.firstName}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "firstName"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Last Name"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.lastName}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the lastName"}
                            name="lastName"
                            // defaultValue={orgFormData.lastName}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "lastName"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Email"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.persEmail}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the persEmail"}
                            name="persEmail"
                            // defaultValue={orgFormData.persEmail}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "persEmail"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Mobile"}
                          isMandotary={true}
                        />
                        <MemoizedComponent
                          orgFormData={orgFormData.mobilePhone}
                        >
                          <PhoneInput
                            autocompleteSearch={true}
                            enableSearch={true}
                            country={"gb"}
                            value={orgFormData.mobilePhone}
                            defaultErrorMessage={"Phone no is required"}
                            inputProps={{
                              name: "mobilePhone",
                              required: false,
                            }}
                            inputStyle={{ height: "52px", width: "100%" }}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "mobilePhone"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Preferred Username"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.userName}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the userName"}
                            name="userName"
                            // defaultValue={orgFormData.userName}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "userName"
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </>
                <>
                  <hr />
                  <Box className="pt-1">
                    <Box className="mb2">
                      <Typography variant="h6" className="">
                        Company Information
                      </Typography>
                    </Box>
                    <Grid container className="" spacing={1}>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Organisation Name"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.name}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the name"}
                            name="name"
                            // defaultValue={orgFormData.name}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(error.isValid, error.errors, "name")}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"AddressLine 1"}
                          isMandotary={true}
                        />
                        <MemoizedComponent
                          orgFormData={orgFormData.addressLine1}
                        >
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the addressLine1"}
                            name="addressLine1"
                            // defaultValue={orgFormData.addressLine1}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "addressLine1"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"AddressLine 2"}
                          isMandotary={false}
                        />
                        <MemoizedComponent
                          orgFormData={orgFormData.addressLine2}
                        >
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the addressLine2"}
                            name="addressLine2"
                            // defaultValue={orgFormData.addressLine2}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {/* {generateErrorList(error.isValid, error.errors, "addressLine2")} */}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"City"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.city}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the city"}
                            name="city"
                            // defaultValue={orgFormData.city}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(error.isValid, error.errors, "city")}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Post Code/Pin Code"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.postcode}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the postcode"}
                            name="postcode"
                            // defaultValue={orgFormData.postcode}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "postcode"
                        )}
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <CustomFormLabel
                          labelName={"Country"}
                          isMandotary={true}
                        />
                        <MemoizedComponent orgFormData={orgFormData.country}>
                          <CustomInput
                            isDisabled={false}
                            readOnly={false}
                            onClick={null}
                            iconPosition={"start"}
                            icon={false}
                            isError={false}
                            label={"Enter the country"}
                            name="country"
                            // defaultValue={orgFormData.country}
                            helperText={""}
                            placeholder={"Please Enter"}
                          />
                        </MemoizedComponent>
                        {generateErrorList(
                          error.isValid,
                          error.errors,
                          "country"
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </>
                <>
                  <hr />
                  <Box className="mb2  pt-1">
                    <Typography variant="h6" className="">
                      Custom domain
                    </Typography>
                  </Box>
                  <Grid container className="" spacing={1}>
                    <Grid item md={6} xs={12}>
                      <DomainChecker
                        callback={(res: any) => {
                          setDomainValue(res);
                        }}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>

                    </Grid>
                    <Grid item md={4} xs={12}>
                      <CustomFormLabel
                        labelName={"Plans"}
                        isMandotary={true}
                      />
                      <CustomSelect
                        isOpen={false}
                        placeholder="Select Type"
                        width={"100%"}
                        name="planTypeId"
                        isColor={false}
                        isDisabled={false}
                        isError={false}
                        helperText={`Property is required.`}
                        defaultValue={planVal}
                        options={planList}
                        handleChange={(e: any) => {
                          console.log("eeeeeeee", e, planVal);
                          handleChangePlan(e);
                        }}
                        isMulti={false}
                        // customClassNames="sml-txt-dropdown new-service"
                      />
                      {generateErrorList(
                        error.isValid,
                        error.errors,
                        "planTypeId"
                      )}
                    </Grid>
                  </Grid>
                  
                  <hr />
                  <MemoizedComponent
                    orgFormData={orgFormData.coreProductId}
                    seconParam={allCoreProducts}
                  >
                    <Box className="mb2  pt-1">
                      <Typography variant="h6" className="">
                        Please select a Core product
                      </Typography>
                    </Box>
                    <FormControl className="w-100">
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue={4}
                        value={orgFormData.coreProductId}
                        onChange={(e: any) => {
                          handleSelectChange(
                            parseInt(e.target.value),
                            "coreProductId"
                          );
                        }}
                        name="radio-buttons-group"
                        className="prodList"
                      >
                        {allCoreProducts.map(
                          (item: { id: any; displayName: string }, index) => (
                            <Box className="prodItem" key={index}>
                              <Box>
                                <FormControlLabel
                                  value={item.id}
                                  control={<Radio />}
                                  label={item.displayName}
                                />
                              </Box>
                            </Box>
                          )
                        )}
                      </RadioGroup>
                    </FormControl>
                  </MemoizedComponent>
                  {generateErrorList(
                    error.isValid,
                    error.errors,
                    "coreProductId"
                  )}
                  <br />
                  <br />
                  <br />
                </>
                <>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    By clicking Submit, you agree to the &nbsp;{" "}
                    <a href={"/#/LicenceAgreement"} target="_blank">
                      Terms and Conditions.
                    </a>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      margin: "auto",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 20,
                        width: "500px",
                        justifyContent: "center",
                      }}
                      className={classes.btnWrapperModal}
                    >
                      <BasicButton
                        onClick={() => {
                          backHandler();
                        }}
                        type={"Cancel"}
                        isLoading={false}
                        isDisabled={false}
                        color="primary"
                        size="large"
                        label="Cancel"
                        variant={"outlined"}
                        endIcon={false}
                      />
                      <BasicButton
                        type="submit"
                        label={"Save"}
                        isLoading={loading}
                        disabled={loading}
                        color="primary"
                        size="large"
                        variant={"contained"}
                        endIcon={<SaveIcon sx={{ width: "20px" }} />}
                      />

                      <br />
                      <br />
                    </div>
                  </div>
                </>
              </div>
            </Container>
          </Box>
        </Box>
      </form>
    </>
  );
}
