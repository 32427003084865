import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, FormControl, Grid, Typography } from "@mui/material";
import { memo, useMemo } from "react";
import { connect } from "react-redux";
import { IState } from "../../../../redux/reducers/rootReducers";
import CommonStyles from "../../../common/CommonStyles";
import AgentPermissionsGroup from "./AgentPermissionsGroup";
import { toggleAllTypeCheckBoxes, updateAgentCheckBoxes } from '../../../../redux/actions/userManagement/role';
const SspTicketTypeOptions:{[key:string]:boolean} = {
    "Create" : true,
    "View" : true,
    "Close" : true,
    "Update" : true,
};
const AgentCheckBoxList = memo((props: any) => {
    const { items = [],dispatch,agentCheckedItems,permissions=[],keyLabel,isSSP=false} = props;
    const classes = CommonStyles();
    const layout = useMemo(() => {
        const selectAllHandler = (id:any,permissions:any[]=[]) =>{
            dispatch(toggleAllTypeCheckBoxes(keyLabel,permissions.map((i:any)=>i.id),id))
        }
        return (<>
            <Grid item xs={6} md={12}>
                {items?.map && items.map((i:any,index:number) => {
                    const permissionsFiltered = permissions.filter((x:any)=> (x.name === "AllowAccess" && !isSSP) || (keyLabel === "TicketTypes" && !isSSP) || (isSSP && keyLabel === "TicketTypes" && SspTicketTypeOptions[x.name]));
                    const checkedItems:any = agentCheckedItems?.[keyLabel]?.[i?.id] || [];
                    const checked = checkedItems.length > 0 && checkedItems.length === permissionsFiltered.length;
                    console.log("agentCheckedItems_ppp",checked)
                    return (<>
                        <div className={`p-1 ${index % 2 === 1 ? '' : 'stripe'}`}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={6} md={3}>
                                    <Box>
                                        <Typography variant="body2" className={`${classes.linkTitle} ${classes.rolesBlueTitle}`}>{i?.name}</Typography>
                                        {i?.hint && <Typography  variant="body2" className={`roleRow-hint`}>{i?.hint}</Typography>}
                                        {/* {keyLabel === 'TicketTypes' && */}
                                            <Typography className={`radio-label`} onClick={()=>{
                                                // changeHandler(id,checked)
                                                selectAllHandler(i?.id,checked ? [] : permissionsFiltered)
                                                }}>
                                                {checked ? <CheckBoxIcon /> :  <CheckBoxOutlineBlankIcon /> }
                                                <span>Select All</span>
                                            </Typography>
                                        {/* } */}
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={9}>
                                    <FormControl >
                                        <AgentPermissionsGroup isSSP={isSSP} fieldId={i?.id} titleKey={keyLabel} permissions={permissionsFiltered} />
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </div>
                    </>)
                })}

            </Grid>
        </>)
    }, [items,agentCheckedItems,classes])
    return (<>
        {layout}
    </>)
})
const mapStateToProps = (state:IState) => {
    return ({
        permissions : state?.permissionReducer?.Data?.data,
        agentCheckedItems : state?.roleReducer?.agentCheckBoxStatus,
    })
};
export default connect(mapStateToProps)(AgentCheckBoxList);//AgentCheckBoxList;//