import React, { memo, useState, useMemo } from "react";
import CommonStyles from "../../../common/CommonStyles";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import CheckboxList from "./CheckboxList";
import SelectAllOption from "./SelectAllOption";


const RoleAccrodianItem = memo(({info,keyLabel}:any) =>{
    const {title}= info;
    const [expanded,setExpand] = useState(false);
    const classes = CommonStyles();
    const toggleCollapse = () =>{
        setExpand(!expanded); 
    }
    const selectAll = useMemo(() => {
        return(<SelectAllOption info={info} />)
    },[])
    const layout = useMemo(() => {
        return <>
            <Grid container spacing={0} alignItems="center">
                {
                    Object.keys(info).map((j: any, index: number) => {
                        if (j !== 'title' && j!=="onlyAllowAccess") {
                            //console.log(labels, j, labels[j])
                            return <React.Fragment key={index}>
                                <CheckboxList 
                                    indexVal={index} 
                                    titleKey={j}
                                    permissions={info[j].permissions ? info[j].permissions : []}
                                />
                            </React.Fragment>
                        }
                    })
                }

            </Grid>
        </>
    }, [])
    return(
        <>
            <Accordion expanded={expanded} className="accordionDtls">
                <AccordionSummary aria-controls={`${title}d-content`} id={`${title}d-header`} className='accrodTitle'>
                    <Box className={`${classes.row} ${classes.justifyBetween} w-100`}>
                        <Box>
                            <Typography>{title}</Typography>
                        </Box>
                        <Box className={`${classes.row} ${classes.alignItemsCenter}`} >
                            {selectAll}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {expanded ?
                                <KeyboardArrowUpOutlinedIcon className="pointer" onClick={toggleCollapse} /> :
                                <KeyboardArrowDownOutlinedIcon className="pointer" onClick={toggleCollapse} /> 
                            }
                        </Box>
                    </Box>

                </AccordionSummary>
                <AccordionDetails>
                    {layout}
                </AccordionDetails>
            </Accordion>
        </>
    )
})

export default RoleAccrodianItem;
// const mapStateToProps = (state:IState) => {
//     return ({
//       systemTypes : state?.systemTypeReducer?.Data,
//       permissions : state?.permissionReducer?.Data?.data,
//     //   state
//     })
// };
// export default connect(mapStateToProps)(RoleAccrodianItem);